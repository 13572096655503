import React, { createContext, useEffect, useRef, useState } from 'react'
import Fuse from 'fuse.js'

export const SearchContext = createContext(null);
const FUSE_OPTIONS = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: true,
    // findAllMatches: true,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.45,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: [
        "company_name"
    ]
};


// Change the pattern
// const pattern = ""

// return fuse.search(pattern)

export default function SearchContextProvider({ children }) {
    const _data = useRef({
        stallData: {},
        sellerData: {}
    });
    const zoomToElementRef = useRef(null)
    const calculateRectFun = useRef(null)
    const [stallId, setStallId] = useState(null);
    const [areaFilter, setAreaFilterId] = useState(null);
    const [sectionFilter, setSectionFilterId] = useState(null);


    const [currentSearchList, setCurrentSearchList] = useState(null)

    const _fuseRef = useRef(null)

    useEffect(() => {
        window.seacrh = search;
        // _fuseRef.current = new Fuse(_data.current.sellerData,, FUSE_OPTIONS);
    }, [])

    const updateSellersData = () => {
        if (_data.current.sellerData) {
            let _sellerData = _data.current.sellerData
            let _sellerKeys = Object.keys(_sellerData);
            let _searchList = _sellerKeys.map(k => _sellerData[k])
            // console.log(_searchList)
            _fuseRef.current = new Fuse(_searchList, FUSE_OPTIONS);
        }
    }

    const clearSearch = () => {
        setCurrentSearchList(null);
    }

    const search = (_pattern) => {
        let result = _fuseRef.current.search(_pattern)
        setCurrentSearchList(result.map(r => r.item));
        // return result
        console.log(result)
    }

    const zts_timerRef = useRef(null);
    const zoomToStall = (id) => {
        if (zts_timerRef.current) {
            clearTimeout(zts_timerRef.current);
        }
        zoomToElementRef.current(document.querySelector(`#${id}_placeholder`), 3);
        zts_timerRef.current = setTimeout(() => {
            calculateRectFun.current(id);
            setStallId(id);
            const closeInfoPopup = () => {
                // console.log("closeInfoPopup")
                setStallId(null)
                window.removeEventListener("click", closeInfoPopup);
            }
            window.addEventListener("click", closeInfoPopup);
        }, 600);

    }

    return (
        <>
            <SearchContext.Provider value={{ _data, currentSearchList, updateSellersData, search, clearSearch, zoomToElementRef, zoomToStall, stallId, setStallId, calculateRectFun, areaFilter, setAreaFilterId, sectionFilter, setSectionFilterId }}>
                {children}
            </SearchContext.Provider>
        </>
    )
}
