import firebase, { apps, database } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";
import { REALDB_CONSTANT } from "../Constant/FirebaseConstant";
import * as XLSX from "xlsx";

const firebaseConfig = {
  apiKey: "AIzaSyBBgAbHlaczfUI-X1aKaQFIre8b1O7sfW8",
  authDomain: "iijs-floorplan.firebaseapp.com",
  databaseURL: "https://iijs-floorplan-default-rtdb.firebaseio.com",
  projectId: "iijs-floorplan",
  storageBucket: "iijs-floorplan.appspot.com",
  messagingSenderId: "144359212350",
  appId: "1:144359212350:web:d734da9f4f6f933e89986f",
  measurementId: "G-4ZFBF8M91S",
};

firebase.initializeApp(firebaseConfig);

export const firebaseApp = firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realDB = firebase.database();

export const analytics = firebase.analytics();

export const storage = firebase.storage();

export const logoutUser = () => {
  return auth.signOut();
};

export const GetSellersBaseData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // const snapshot = await realDB.ref(REALDB_CONSTANT.SellerBaseData).get();
      const snapshot = await realDB
        .ref(REALDB_CONSTANT.SellerBaseData)
        .once("value");
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Sellers Base Data found!!");
        resolve({});
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const GetSingleSellerBaseData = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await realDB
        .ref(`${REALDB_CONSTANT.SellerBaseData}/${sellerId}`)
        .get();
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Seller Base Data found!!");
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const GetAllSellersStallData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // const snapshot = await realDB.ref(REALDB_CONSTANT.Sellersdata).get();
      const snapshot = await realDB
        .ref(REALDB_CONSTANT.Sellersdata)
        .once("value");
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Sellers Stall Data found!!");
        resolve({});
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const GetSingleSellerStallData = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await realDB
        .ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`)
        .get();
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Seller Stall Data found!!");
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const UpdateSellerStallData = (sellerId, payload, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`);
      await nodeRef.update(payload);
      const _nodeRef = realDB.ref(
        `${REALDB_CONSTANT.SellerBaseData}/${sellerId}`
      );
      let _pa = {
        ...payload,
      };
      if (user) {
        _pa = {
          ..._pa,
          admin: {
            uid: user.uid,
            email: user.email,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
          },
        };
      }
      delete _pa.stallData;
      await _nodeRef.update(_pa);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const DeleteSellersStallData = (sellerId, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`);
      await nodeRef.remove();
      if (user) {
        const _nodeRef = realDB.ref(
          `${REALDB_CONSTANT.SellerBaseData}/${sellerId}`
        );
        _nodeRef.update({
          admin: {
            uid: user.uid,
            email: user.email,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
          },
        });
      }
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getDataForExcel = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseNodeRef = realDB.ref(`${REALDB_CONSTANT.SellerBaseData}`);
      const baseNodeData = await baseNodeRef.get();
      if (!baseNodeData.exists()) {
        return;
      }
      let baseNodeDataObj = Object.values(baseNodeData.val());

      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}`);
      const nodeData = await nodeRef.get();
      let nodeDataObj = {};
      if (nodeData.exists()) {
        nodeDataObj = Object.values(nodeData.val());
      }
      console.log(nodeDataObj);

      let nodeDataObjHash = {};
      nodeDataObj.forEach((elementData) => {
        nodeDataObjHash[elementData.comapnyId] = {
          ...elementData,
        };
      });
      let result = baseNodeDataObj.map((_bnd) => {
        let _adminData = { ..._bnd.admin };
        delete _bnd.admin;

        let _r = {
          ..._bnd,
        };
        Object.keys(_adminData).forEach((k) => {
          _r[`Admin_${k}`] = _adminData[k];
        });
        if (nodeDataObjHash[_bnd.comapnyId]) {
          let stallsData = nodeDataObjHash[_bnd.comapnyId].stallData;
          Object.keys(stallsData).forEach((stallId, index) => {
            let _index = index + 1;
            let stallData = stallsData[stallId];
            Object.keys(stallData).forEach((k) => {
              _r[`Stall${_index}_${k}`] = stallData[k];
            });
          });
        }
        return _r;
      });
      console.log(result);
      exportFile(
        result,
        "sheet1",
        "IIJS_DATA" + new Date().getTime() + ".xlsx"
      );
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const exportFile = (
  data,
  sheetName,
  filename,
  headerOrdering = null
) => {
  try {
    console.log("exportFile");
    const wb = XLSX.utils.book_new();
    let wsAll = null;
    if (headerOrdering) {
      wsAll = XLSX.utils.json_to_sheet(data, {
        header: headerOrdering,
      });
    } else {
      wsAll = XLSX.utils.json_to_sheet(data);
    }
    XLSX.utils.book_append_sheet(wb, wsAll, sheetName);
    XLSX.writeFile(wb, filename);
  } catch (error) {
    console.error(error);
  }
};
window.getDataForExcel = getDataForExcel;

const newData = {
  7000038003: {
    comapnyId: 7000038003,
    company_name: "SHREE DEV JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000003422: {
    comapnyId: 7000003422,
    company_name: "TANVI GOLD CAST LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 54,
  },
  7000060964: {
    comapnyId: 7000060964,
    company_name: "YASHODA JAGDISH & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003614: {
    comapnyId: 7000003614,
    company_name: "VITHRAAG JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000061008: {
    comapnyId: 7000061008,
    company_name: "PP ABHAY JEWELLERS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000051554: {
    comapnyId: 7000051554,
    company_name: "ROYAL GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000012314: {
    comapnyId: 7000012314,
    company_name: "RE JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009491: {
    comapnyId: 7000009491,
    company_name: "RISHAB JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000060707: {
    comapnyId: 7000060707,
    company_name: "SUTRAM JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000015786: {
    comapnyId: 7000015786,
    company_name: "SPARKLE DIAM PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000007195: {
    comapnyId: 7000007195,
    company_name: "P.R. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000038210: {
    comapnyId: 7000038210,
    company_name: "VS JEWELLERY WHOLESALER GLOBAL PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000058887: {
    comapnyId: 7000058887,
    company_name: "RUSHABH JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000003323: {
    comapnyId: 7000003323,
    company_name: "CHOKSHI VACHHARAJ MAKANJI & COMPANY",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000001439: {
    comapnyId: 7000001439,
    company_name: "ARIHANT DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000051439: {
    comapnyId: 7000051439,
    company_name: "AARYA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066690: {
    comapnyId: 7000066690,
    company_name: "AAGAM CHAIN",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000000608: {
    comapnyId: 7000000608,
    company_name: "JEWEL HOUSE",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000067204: {
    comapnyId: 7000067204,
    company_name: "JH AND RH CHUDIWALA",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000012776: {
    comapnyId: 7000012776,
    company_name: "SANJAY BAIRATHI GEMS LIMITED",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000004421: {
    comapnyId: 7000004421,
    company_name: "J.P. EXPORTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000052722: {
    comapnyId: 7000052722,
    company_name: "FUSION JEWELS INDIA",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000066435: {
    comapnyId: 7000066435,
    company_name: "R. C. JAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000005147: {
    comapnyId: 7000005147,
    company_name: "SHA RATANCHAND DILIPKUMAR OSWAL",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009638: {
    comapnyId: 7000009638,
    company_name: "AAKAR JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000057359: {
    comapnyId: 7000057359,
    company_name: "ANJALI DIAMONDS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_6",
    area: 72,
  },
  7000067684: {
    comapnyId: 7000067684,
    company_name: "ANMOL JEWELL",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000048346: {
    comapnyId: 7000048346,
    company_name: "MANIRATNA CHAINS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000075847: {
    comapnyId: 7000075847,
    company_name: "SHREE PAGARIYA JEWELLERS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000015658: {
    comapnyId: 7000015658,
    company_name: "SILVER CREATIONS PVT. LTD.",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000003110: {
    comapnyId: 7000003110,
    company_name: "PRAGYA JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000006378: {
    comapnyId: 7000006378,
    company_name: "EON JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000059155: {
    comapnyId: 7000059155,
    company_name: "M G",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000053365: {
    comapnyId: 7000053365,
    company_name: "HUNAR GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000008087: {
    comapnyId: 7000008087,
    company_name: "MAHEK GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000035364: {
    comapnyId: 7000035364,
    company_name: "M. V. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000011961: {
    comapnyId: 7000011961,
    company_name: "ACHAL JEWELS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 54,
  },
  7000013103: {
    comapnyId: 7000013103,
    company_name: "BLISS GEMS & MINERALS",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000038267: {
    comapnyId: 7000038267,
    company_name: "M. SASHI BADALIA & SONS PVT. LTD.",
    region: "region_5",
    section: "sectionData_3",
    area: 36,
  },
  7000057763: {
    comapnyId: 7000057763,
    company_name: "VESARA JEWELS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000050427: {
    comapnyId: 7000050427,
    company_name: "MOMENTS925 SILVER INDIA LLP",
    region: "region_1",
    section: "sectionData_4",
    area: 54,
  },
  7000066566: {
    comapnyId: 7000066566,
    company_name: "SRI GGN GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000012137: {
    comapnyId: 7000012137,
    company_name: "SAAZ JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000057587: {
    comapnyId: 7000057587,
    company_name: "SANKALP JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000016314: {
    comapnyId: 7000016314,
    company_name: "JASANI INDIA PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000058500: {
    comapnyId: 7000058500,
    company_name: "TAHI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000005822: {
    comapnyId: 7000005822,
    company_name: "RANKA TRADERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000027838: {
    comapnyId: 7000027838,
    company_name: "ADASTRA JEWELRY PVT. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000004779: {
    comapnyId: 7000004779,
    company_name: "JEWEL GOLDI (INDIA)",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000066434: {
    comapnyId: 7000066434,
    company_name: "INDERLAL BALMUKAND GEMS & JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000019011: {
    comapnyId: 7000019011,
    company_name: "N K JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000006456: {
    comapnyId: 7000006456,
    company_name: "KIRAN JEWELS INDIA",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000066667: {
    comapnyId: 7000066667,
    company_name: "SWASTIK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000011800: {
    comapnyId: 7000011800,
    company_name: "AKSHAR JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000002195: {
    comapnyId: 7000002195,
    company_name: "BHAIRAV GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003350: {
    comapnyId: 7000003350,
    company_name: "RAKSHIKA JEWELS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000045028: {
    comapnyId: 7000045028,
    company_name: "SANJAY KUMAR BAVISHI AND SON",
    region: "region_5",
    section: "sectionData_1",
    area: 12,
  },
  7000038422: {
    comapnyId: 7000038422,
    company_name: "MANYATA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000059611: {
    comapnyId: 7000059611,
    company_name: "STRIBRNY CREATIONS LLP",
    region: "region_5",
    section: "sectionData_4",
    area: 9,
  },
  7000004954: {
    comapnyId: 7000004954,
    company_name: "CHINTAMANI GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000012917: {
    comapnyId: 7000012917,
    company_name: "RUSHABH DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000057626: {
    comapnyId: 7000057626,
    company_name: "SHRI PEARL CORAL",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000004731: {
    comapnyId: 7000004731,
    company_name: "VAISHNAVI GEMS & JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000044128: {
    comapnyId: 7000044128,
    company_name: "M. M. CORPORATION",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000052443: {
    comapnyId: 7000052443,
    company_name: "NAKODA GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000053138: {
    comapnyId: 7000053138,
    company_name: "SILVER EMPIRE",
    region: "region_1",
    section: "sectionData_4",
    area: 36,
  },
  7000015626: {
    comapnyId: 7000015626,
    company_name: "SWARNSARITA JEWELS INDIA LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013277: {
    comapnyId: 7000013277,
    company_name: "S.R. JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000052552: {
    comapnyId: 7000052552,
    company_name: "KAASHVI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000017199: {
    comapnyId: 7000017199,
    company_name: "CREATIVE JEWEL",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000003476: {
    comapnyId: 7000003476,
    company_name: "HARE RAMA HARE KRISHNA CORALS PVT. LTD.",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000057493: {
    comapnyId: 7000057493,
    company_name: "ZAMBAD JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011564: {
    comapnyId: 7000011564,
    company_name: "RATNADEEP GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061049: {
    comapnyId: 7000061049,
    company_name: "SUBH MANGAL GOLD PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000014832: {
    comapnyId: 7000014832,
    company_name: "MEHTA GOLD PVT. LTD.",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000058830: {
    comapnyId: 7000058830,
    company_name: "MEHTA VENTURES",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000038473: {
    comapnyId: 7000038473,
    company_name: "KARNI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000003352: {
    comapnyId: 7000003352,
    company_name: "J.S JEWEL OM LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000061192: {
    comapnyId: 7000061192,
    company_name: "PREMVATI JEWELLERY PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000010059: {
    comapnyId: 7000010059,
    company_name: "P. MANGATRAM JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000067081: {
    comapnyId: 7000067081,
    company_name: "KANAK GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000012682: {
    comapnyId: 7000012682,
    company_name: "TULSI DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000048633: {
    comapnyId: 7000048633,
    company_name: "SAAJ CREATIONS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000002205: {
    comapnyId: 7000002205,
    company_name: "VIPS JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003412: {
    comapnyId: 7000003412,
    company_name: "H.K. JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000003386: {
    comapnyId: 7000003386,
    company_name: "DEEPA JEWELLERS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000068345: {
    comapnyId: 7000068345,
    company_name: "AAYU JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000003610: {
    comapnyId: 7000003610,
    company_name: "SHINJINI JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000068348: {
    comapnyId: 7000068348,
    company_name: "ANOOTHI JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057564: {
    comapnyId: 7000057564,
    company_name: "SHILPA DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000000535: {
    comapnyId: 7000000535,
    company_name: "RATAN JEWELLERS",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000017417: {
    comapnyId: 7000017417,
    company_name: "DIAMOND CREATIONS",
    region: "region_1",
    section: "sectionData_1",
    area: 72,
  },
  7000053115: {
    comapnyId: 7000053115,
    company_name: "MUKESH & BROTHERS JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000008913: {
    comapnyId: 7000008913,
    company_name: "DEVI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000060837: {
    comapnyId: 7000060837,
    company_name: "SHREE I JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000004123: {
    comapnyId: 7000004123,
    company_name: "ILLUSION SOLITAIRE DIAMOND JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000009283: {
    comapnyId: 7000009283,
    company_name: "CLASSIC ORNAMENTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000052685: {
    comapnyId: 7000052685,
    company_name: "S K JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000014795: {
    comapnyId: 7000014795,
    company_name: "KAILASHNATH & CO",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000015511: {
    comapnyId: 7000015511,
    company_name: "SHREE GOLD JEWEL CREATOR",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000066646: {
    comapnyId: 7000066646,
    company_name: "SSVAR LLP",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000001350: {
    comapnyId: 7000001350,
    company_name: "JAIPUR JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000009672: {
    comapnyId: 7000009672,
    company_name: "DHANLAXMI JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000004586: {
    comapnyId: 7000004586,
    company_name: "SPARKLING GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000007857: {
    comapnyId: 7000007857,
    company_name: "M.A. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000006382: {
    comapnyId: 7000006382,
    company_name: "DHARMA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000001101: {
    comapnyId: 7000001101,
    company_name: "SAP JEWELS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000005872: {
    comapnyId: 7000005872,
    company_name: "REAL PEARLS (BUNTY MOTIWALA)",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000046142: {
    comapnyId: 7000046142,
    company_name: "HARESH AND SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000070469: {
    comapnyId: 7000070469,
    company_name: "SETURIA ORNAMENTS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_2",
    area: 27,
  },
  7000001307: {
    comapnyId: 7000001307,
    company_name: "M.J. & SONS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069089: {
    comapnyId: 7000069089,
    company_name: "RAJ IMPEX",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000007065: {
    comapnyId: 7000007065,
    company_name: "VERO STELLA JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000004371: {
    comapnyId: 7000004371,
    company_name: "J.B. AND BROTHERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 36,
  },
  7000000970: {
    comapnyId: 7000000970,
    company_name: "PURPLE",
    region: "region_1",
    section: "sectionData_4",
    area: 27,
  },
  7000003529: {
    comapnyId: 7000003529,
    company_name: "HARSH JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000051949: {
    comapnyId: 7000051949,
    company_name: "VAKPATI JEWELLERS LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003596: {
    comapnyId: 7000003596,
    company_name: "VARAKRUPA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000053082: {
    comapnyId: 7000053082,
    company_name: "SHAKTI ENTERPRISE",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000000540: {
    comapnyId: 7000000540,
    company_name: "SWAROOP JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000017661: {
    comapnyId: 7000017661,
    company_name: "ARHAM JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000016244: {
    comapnyId: 7000016244,
    company_name: "TRADITIONS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000011035: {
    comapnyId: 7000011035,
    company_name: "PRISM ENTERPRISES PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000008173: {
    comapnyId: 7000008173,
    company_name: "TINASHE CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014604: {
    comapnyId: 7000014604,
    company_name: "BHINDI MANUFACTURERS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000004722: {
    comapnyId: 7000004722,
    company_name: "GOLD STRINGS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000054221: {
    comapnyId: 7000054221,
    company_name: "KANGANAM",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000057304: {
    comapnyId: 7000057304,
    company_name: "ASHTALAXMI JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000060853: {
    comapnyId: 7000060853,
    company_name: "SAMRUDDHI JEWELCRAFT PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000038476: {
    comapnyId: 7000038476,
    company_name: "GEMSPOT",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000011378: {
    comapnyId: 7000011378,
    company_name: "JKS JEWELS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000016023: {
    comapnyId: 7000016023,
    company_name: "SUPERGEMS JEWELLERY MFG. PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000059817: {
    comapnyId: 7000059817,
    company_name: "K. RAJ ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000008459: {
    comapnyId: 7000008459,
    company_name: "DEVANSH CREATION",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000052389: {
    comapnyId: 7000052389,
    company_name: "EFFULGENT JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000013267: {
    comapnyId: 7000013267,
    company_name: "SHREE OMKAR JEWELLER S (P) LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000009097: {
    comapnyId: 7000009097,
    company_name: "GOLD MINE",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000000459: {
    comapnyId: 7000000459,
    company_name: "MUSKAAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000052707: {
    comapnyId: 7000052707,
    company_name: "MODI ORNAMENTS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000077582: {
    comapnyId: 7000077582,
    company_name: "SAMBAV DIAMOND LLP",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000004702: {
    comapnyId: 7000004702,
    company_name: "BINNYS JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000026759: {
    comapnyId: 7000026759,
    company_name: "A` STAR JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000052715: {
    comapnyId: 7000052715,
    company_name: "RUSHABH JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000005095: {
    comapnyId: 7000005095,
    company_name: "SILBER INTERNATIONAL",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000057645: {
    comapnyId: 7000057645,
    company_name: "THASHNA JEWELLERY",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000060789: {
    comapnyId: 7000060789,
    company_name: "ADITYA GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000060495: {
    comapnyId: 7000060495,
    company_name: "SHISH JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 27,
  },
  7000000037: {
    comapnyId: 7000000037,
    company_name: "P&S GALVASOLS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000063089: {
    comapnyId: 7000063089,
    company_name: "AVISHI GEMS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000052513: {
    comapnyId: 7000052513,
    company_name: "CIYAZA INDUSTRIES LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000046255: {
    comapnyId: 7000046255,
    company_name: "PALM JEWELS LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000007277: {
    comapnyId: 7000007277,
    company_name: "KRISHNA BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003689: {
    comapnyId: 7000003689,
    company_name: "HMV GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000009937: {
    comapnyId: 7000009937,
    company_name: "AARYA24KT INDIA PVT.LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000010872: {
    comapnyId: 7000010872,
    company_name: "PRECIOUS GEMS & JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000012678: {
    comapnyId: 7000012678,
    company_name: "ROOPSHREE JEWELLERS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000013532: {
    comapnyId: 7000013532,
    company_name: "AKASH GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000059472: {
    comapnyId: 7000059472,
    company_name: "HARI OM JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000009450: {
    comapnyId: 7000009450,
    company_name: "NATIONAL FACETS",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000066627: {
    comapnyId: 7000066627,
    company_name: "SKY GEMS",
    region: "region_2",
    section: "sectionData_13",
    area: 9,
  },
  7000051697: {
    comapnyId: 7000051697,
    company_name: "GOVINDJI ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000068519: {
    comapnyId: 7000068519,
    company_name: "VIDHAN JEWELLS AND DIAMONDS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000047484: {
    comapnyId: 7000047484,
    company_name: "MAA SATTI JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 27,
  },
  7000057256: {
    comapnyId: 7000057256,
    company_name: "RAJ SHREE JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000052582: {
    comapnyId: 7000052582,
    company_name: "NILKANTH DIA JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000012759: {
    comapnyId: 7000012759,
    company_name: "ROYAL CHAINS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061426: {
    comapnyId: 7000061426,
    company_name: "VIJAY JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000013196: {
    comapnyId: 7000013196,
    company_name: "HARI GEMS",
    region: "region_2",
    section: "sectionData_13",
    area: 9,
  },
  7000052939: {
    comapnyId: 7000052939,
    company_name: "OSTWAL JEWELS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000066539: {
    comapnyId: 7000066539,
    company_name: "DEEPA JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000010410: {
    comapnyId: 7000010410,
    company_name: "PEACEMOON TRADERS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000008816: {
    comapnyId: 7000008816,
    company_name: "AB JEWELS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000014812: {
    comapnyId: 7000014812,
    company_name: "LOTUS JEWELLERY CREATION",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000016986: {
    comapnyId: 7000016986,
    company_name: "SWASTIK JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000005712: {
    comapnyId: 7000005712,
    company_name: "KANAKRATNA EXIM PVT.LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000002657: {
    comapnyId: 7000002657,
    company_name: "GLITTER JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000005734: {
    comapnyId: 7000005734,
    company_name: "KANTILAL CHHOTALAL",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000037535: {
    comapnyId: 7000037535,
    company_name: "L STAR",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000007269: {
    comapnyId: 7000007269,
    company_name: "KISHORE MOTIWALA",
    region: "region_4",
    section: "sectionData_13",
    area: 18,
  },
  7000035942: {
    comapnyId: 7000035942,
    company_name: "T.G. ART INDIA",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000287: {
    comapnyId: 7000000287,
    company_name: "TATIWALAS GEHNA",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000000218: {
    comapnyId: 7000000218,
    company_name: "AWESOME SPARKLERS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000010602: {
    comapnyId: 7000010602,
    company_name: "VALDARIA IMPEX PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000002104: {
    comapnyId: 7000002104,
    company_name: "TIRTH GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000044392: {
    comapnyId: 7000044392,
    company_name: "SORA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000016983: {
    comapnyId: 7000016983,
    company_name: "PAHINI JEWELLERY LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000027790: {
    comapnyId: 7000027790,
    company_name: "SRI MONDAL JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000006598: {
    comapnyId: 7000006598,
    company_name: "KRIPA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000050137: {
    comapnyId: 7000050137,
    company_name: "VALENTINE JADAU",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000017278: {
    comapnyId: 7000017278,
    company_name: "ANM JEWELLERYLINE",
    region: "region_6",
    section: "sectionData_4",
    area: 9,
  },
  7000066079: {
    comapnyId: 7000066079,
    company_name: "JEWEL PARK",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000008052: {
    comapnyId: 7000008052,
    company_name: "SIDDI VINAYAK EXPORT",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000063189: {
    comapnyId: 7000063189,
    company_name: "HUNNAR GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000007738: {
    comapnyId: 7000007738,
    company_name: "JENISHA JEWELS ARTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000009932: {
    comapnyId: 7000009932,
    company_name: "VAJRA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000003210: {
    comapnyId: 7000003210,
    company_name: "SAKARIYA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011024: {
    comapnyId: 7000011024,
    company_name: "PRIORITY JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000009745: {
    comapnyId: 7000009745,
    company_name: "BAID TRADING CORPORATION",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000052651: {
    comapnyId: 7000052651,
    company_name: "SHIVENDRA JEWELLERS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061302: {
    comapnyId: 7000061302,
    company_name: "KLASSIC MOUNTS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000011746: {
    comapnyId: 7000011746,
    company_name: "ZAVERAT",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000015068: {
    comapnyId: 7000015068,
    company_name: "HARSH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000044490: {
    comapnyId: 7000044490,
    company_name: "T. GULABCHAND JAIN",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000006889: {
    comapnyId: 7000006889,
    company_name: "NAHAR DIAMOND",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000005320: {
    comapnyId: 7000005320,
    company_name: "VAGONI TRADE LINK",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000012286: {
    comapnyId: 7000012286,
    company_name: "RAJAT EMPORIUM",
    region: "region_1",
    section: "sectionData_4",
    area: 36,
  },
  7000008006: {
    comapnyId: 7000008006,
    company_name: "M.U. JEWELLERS PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011269: {
    comapnyId: 7000011269,
    company_name: "MANGAL MANI JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000067822: {
    comapnyId: 7000067822,
    company_name: "JAINA DIAMOND AND GOLD PVT LTD",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000059559: {
    comapnyId: 7000059559,
    company_name: "GITA JEWELS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000002059: {
    comapnyId: 7000002059,
    company_name: "HEM RATNA JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000000382: {
    comapnyId: 7000000382,
    company_name: "D.N. JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000044818: {
    comapnyId: 7000044818,
    company_name: "GOLDEN TOUCH ARTWARE PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000050856: {
    comapnyId: 7000050856,
    company_name: "SWARN VILLA",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066095: {
    comapnyId: 7000066095,
    company_name: "VIJAYKUMAR ABASAHEB BHOSALE",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000008346: {
    comapnyId: 7000008346,
    company_name: "THIRUMALA",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000038025: {
    comapnyId: 7000038025,
    company_name: "SURESH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003212: {
    comapnyId: 7000003212,
    company_name: "PANI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069804: {
    comapnyId: 7000069804,
    company_name: "SIAM JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000013498: {
    comapnyId: 7000013498,
    company_name: "CHUNOBA GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000063693: {
    comapnyId: 7000063693,
    company_name: "ARITZA GOLD PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000000857: {
    comapnyId: 7000000857,
    company_name: "NAKSHATRA JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000106: {
    comapnyId: 7000000106,
    company_name: "BONITAS JEWELS PVT.L TD.",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000003928: {
    comapnyId: 7000003928,
    company_name: "H.M.T. ORNAMENT",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000047726: {
    comapnyId: 7000047726,
    company_name: "VINATI JEWELLERS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000009553: {
    comapnyId: 7000009553,
    company_name: "MEHTA GOLD AND DIAMONDS",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000066476: {
    comapnyId: 7000066476,
    company_name: "MAHAVEER JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000013234: {
    comapnyId: 7000013234,
    company_name: "RATHOD JEWELLERY MFG. PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000007478: {
    comapnyId: 7000007478,
    company_name: "LAXMI DIA JEWEL PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000066821: {
    comapnyId: 7000066821,
    company_name: "CIARA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000026533: {
    comapnyId: 7000026533,
    company_name: "BHAGWATI JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000058871: {
    comapnyId: 7000058871,
    company_name: "ROYAL 925 STERLING SILVER JEWELLERY",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000002021: {
    comapnyId: 7000002021,
    company_name: "SWASTIK JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000069503: {
    comapnyId: 7000069503,
    company_name: "RADIANT JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000052307: {
    comapnyId: 7000052307,
    company_name: "BRAHMANI EXPORT",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000004598: {
    comapnyId: 7000004598,
    company_name: "SUNBERA EXPORTS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000013304: {
    comapnyId: 7000013304,
    company_name: "PADMAVATI CHAINS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000006019: {
    comapnyId: 7000006019,
    company_name: "DISHA ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007187: {
    comapnyId: 7000007187,
    company_name: "M L KANHAIYALAL JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000014981: {
    comapnyId: 7000014981,
    company_name: "PARTH ORNAMENTS PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000069583: {
    comapnyId: 7000069583,
    company_name: "GULABRAI PRABHUDAS & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000011483: {
    comapnyId: 7000011483,
    company_name: "BHINDI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000001910: {
    comapnyId: 7000001910,
    company_name: "RITIKA CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000049868: {
    comapnyId: 7000049868,
    company_name: "N.K. IMPEX",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000012302: {
    comapnyId: 7000012302,
    company_name: "R R JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000013228: {
    comapnyId: 7000013228,
    company_name: "RIDHI SIDHI GEMS & JEWELLERY",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000067339: {
    comapnyId: 7000067339,
    company_name: "PNR MAHALAKSHMI JEWELLERY PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000002410: {
    comapnyId: 7000002410,
    company_name: "SWAGAT JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000069611: {
    comapnyId: 7000069611,
    company_name: "S V JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000007332: {
    comapnyId: 7000007332,
    company_name: "AKASH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009926: {
    comapnyId: 7000009926,
    company_name: "B. SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000009168: {
    comapnyId: 7000009168,
    company_name: "A.B. GEMS",
    region: "region_2",
    section: "sectionData_13",
    area: 9,
  },
  7000006851: {
    comapnyId: 7000006851,
    company_name: "JHALAK JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000069814: {
    comapnyId: 7000069814,
    company_name: "BHIKSHU JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000016915: {
    comapnyId: 7000016915,
    company_name: "ALANKAR INTERNATIONAL PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000026241: {
    comapnyId: 7000026241,
    company_name: "AVNI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000002635: {
    comapnyId: 7000002635,
    company_name: "SETS JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000058916: {
    comapnyId: 7000058916,
    company_name: "R K BRACELETS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000058828: {
    comapnyId: 7000058828,
    company_name: "MEHTA JEWELS EMPORIUM",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000003581: {
    comapnyId: 7000003581,
    company_name: "HEENA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000038537: {
    comapnyId: 7000038537,
    company_name: "SHILP CHAINS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000008285: {
    comapnyId: 7000008285,
    company_name: "ANNORAA CREATIONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010388: {
    comapnyId: 7000010388,
    company_name: "PAWAN & COMPANY",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000016975: {
    comapnyId: 7000016975,
    company_name: "SAANCHI GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010428: {
    comapnyId: 7000010428,
    company_name: "R. KOTHARI & CO. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011565: {
    comapnyId: 7000011565,
    company_name: "GURUKRUPA EXPORTS",
    region: "region_3",
    section: "sectionData_1",
    area: 54,
  },
  7000075734: {
    comapnyId: 7000075734,
    company_name: "ORO JEWELS",
    region: "region_1",
    section: "sectionData_7",
    area: 18,
  },
  7000003551: {
    comapnyId: 7000003551,
    company_name: "LILI STAR JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000011822: {
    comapnyId: 7000011822,
    company_name: "DIVAS JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000070046: {
    comapnyId: 7000070046,
    company_name: "MUSKAN JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 12,
  },
  7000007230: {
    comapnyId: 7000007230,
    company_name: "OM JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000047197: {
    comapnyId: 7000047197,
    company_name: "CHARBHUJA JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000053088: {
    comapnyId: 7000053088,
    company_name: "EKTA DIAMOND LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000001888: {
    comapnyId: 7000001888,
    company_name: "MAA SILVER/ MAA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008226: {
    comapnyId: 7000008226,
    company_name: "RIDDHIMA CHAIN AND JEWELLERY",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000076668: {
    comapnyId: 7000076668,
    company_name: "RIDDHI JEWEL",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000000563: {
    comapnyId: 7000000563,
    company_name: "CHAIN EXPERTS AND JE WELLERS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061084: {
    comapnyId: 7000061084,
    company_name: "SHREE JI JEWELLERY MANUFACTURERS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000010575: {
    comapnyId: 7000010575,
    company_name: "TAHILRAM TIRTHDAS JEWELLERS",
    region: "region_2",
    section: "sectionData_13",
    area: 18,
  },
  7000012321: {
    comapnyId: 7000012321,
    company_name: "VINAYAK JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000047491: {
    comapnyId: 7000047491,
    company_name: "S R GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000016525: {
    comapnyId: 7000016525,
    company_name: "VEER GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000068480: {
    comapnyId: 7000068480,
    company_name: "H. D. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069904: {
    comapnyId: 7000069904,
    company_name: "SHREE PRAMUKH ORNAMENTS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000009135: {
    comapnyId: 7000009135,
    company_name: "MURARI EXPORTS",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000009643: {
    comapnyId: 7000009643,
    company_name: "R B JEWELS AND GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000016833: {
    comapnyId: 7000016833,
    company_name: "AURA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000015242: {
    comapnyId: 7000015242,
    company_name: "SHUBH DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000063124: {
    comapnyId: 7000063124,
    company_name: "NITYAS GEMS AND JEWELLERY PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 12,
  },
  7000052984: {
    comapnyId: 7000052984,
    company_name: "V. J. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057275: {
    comapnyId: 7000057275,
    company_name: "TJ JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000010135: {
    comapnyId: 7000010135,
    company_name: "AADISH GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014924: {
    comapnyId: 7000014924,
    company_name: "RAMESHCHAND & SONS J EWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000003208: {
    comapnyId: 7000003208,
    company_name: "MINALAXMI EXIM",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000026: {
    comapnyId: 7000000026,
    company_name: "PUSHPA JEWELLERS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 27,
  },
  7000019018: {
    comapnyId: 7000019018,
    company_name: "SESMAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000012606: {
    comapnyId: 7000012606,
    company_name: "SHREEJI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000044497: {
    comapnyId: 7000044497,
    company_name: "RAJ GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007360: {
    comapnyId: 7000007360,
    company_name: "GARG GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000057314: {
    comapnyId: 7000057314,
    company_name: "TJ GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000007436: {
    comapnyId: 7000007436,
    company_name: "LADIWALA ENTERPRISES",
    region: "region_4",
    section: "sectionData_2",
    area: 12,
  },
  7000053541: {
    comapnyId: 7000053541,
    company_name: "SAMSAA",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013202: {
    comapnyId: 7000013202,
    company_name: "LAL GEMS",
    region: "region_2",
    section: "sectionData_13",
    area: 18,
  },
  7000051563: {
    comapnyId: 7000051563,
    company_name: "VAJRA",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000013212: {
    comapnyId: 7000013212,
    company_name: "S.K. JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000004280: {
    comapnyId: 7000004280,
    company_name: "MAYFAIR CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000009103: {
    comapnyId: 7000009103,
    company_name: "SHIVAM JEWELS AND ARTS LLP",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000005341: {
    comapnyId: 7000005341,
    company_name: "JEWELSMITH",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000044649: {
    comapnyId: 7000044649,
    company_name: "VARDHMAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000038426: {
    comapnyId: 7000038426,
    company_name: "S.P.SHAH & SONS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000017249: {
    comapnyId: 7000017249,
    company_name: "MANAK JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000039496: {
    comapnyId: 7000039496,
    company_name: "GREENLAB DIAMONDS LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 18,
  },
  7000054203: {
    comapnyId: 7000054203,
    company_name: "SANKESHWAR JEWELS",
    region: "region_1",
    section: "sectionData_4",
    area: 12,
  },
  7000005861: {
    comapnyId: 7000005861,
    company_name: "JAI GULAB DEV JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000072782: {
    comapnyId: 7000072782,
    company_name: "SARDA JEWELLERS INDIA PVT LTD",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000017232: {
    comapnyId: 7000017232,
    company_name: "R.B. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000006803: {
    comapnyId: 7000006803,
    company_name: "LAXMI JEWELLERY CHENNAI PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000066457: {
    comapnyId: 7000066457,
    company_name: "GREENLAB JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 54,
  },
  7000012886: {
    comapnyId: 7000012886,
    company_name: "H.P.SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000017234: {
    comapnyId: 7000017234,
    company_name: "NELKT CREATION",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000016520: {
    comapnyId: 7000016520,
    company_name: "VEEKAY DIAMANTS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000014272: {
    comapnyId: 7000014272,
    company_name: "BAANGANGA GOLD & DIAMOND (I) PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000011769: {
    comapnyId: 7000011769,
    company_name: "RAJ JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000015061: {
    comapnyId: 7000015061,
    company_name: "SIROYA CHAMPALAL UTTAMCHAND",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000012459: {
    comapnyId: 7000012459,
    company_name: "ANMOL SWARN INDIA PVT LTD.",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000005484: {
    comapnyId: 7000005484,
    company_name: "K.G.K. CREATIONS (INDIA) PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000012122: {
    comapnyId: 7000012122,
    company_name: "PADMAVATI JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000053113: {
    comapnyId: 7000053113,
    company_name: "J J J JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000069538: {
    comapnyId: 7000069538,
    company_name: "PRECIOUS GEMS AND JEWELLERY",
    region: "region_4",
    section: "sectionData_13",
    area: 12,
  },
  7000053117: {
    comapnyId: 7000053117,
    company_name: "MANGALDEEP CHAINS",
    region: "region_1",
    section: "sectionData_2",
    area: 54,
  },
  7000016779: {
    comapnyId: 7000016779,
    company_name: "HREENKAR JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069884: {
    comapnyId: 7000069884,
    company_name: "TIARA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000057605: {
    comapnyId: 7000057605,
    company_name: "HEEDARIO GEMS & JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000014895: {
    comapnyId: 7000014895,
    company_name: "SAHELI JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010185: {
    comapnyId: 7000010185,
    company_name: "SKY GOLD LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000003007: {
    comapnyId: 7000003007,
    company_name: "GUNJAN JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000057559: {
    comapnyId: 7000057559,
    company_name: "PORWAL PALACE",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000009736: {
    comapnyId: 7000009736,
    company_name: "AARPEE BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000050172: {
    comapnyId: 7000050172,
    company_name: "JEWEL & CHAINZ",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000000390: {
    comapnyId: 7000000390,
    company_name: "D.P. DESIGNS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000010714: {
    comapnyId: 7000010714,
    company_name: "PRAKASH GOLD PALACE (P) LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000004348: {
    comapnyId: 7000004348,
    company_name: "SANGAM JEWELS N GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000057595: {
    comapnyId: 7000057595,
    company_name: "VEER JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007811: {
    comapnyId: 7000007811,
    company_name: "TEJ JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000001917: {
    comapnyId: 7000001917,
    company_name: "V U CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003777: {
    comapnyId: 7000003777,
    company_name: "INTERNATIONAL GEMOLOGICAL INS T. (I) PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000008275: {
    comapnyId: 7000008275,
    company_name: "MOMAI JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000060785: {
    comapnyId: 7000060785,
    company_name: "NANESH DIAMONDS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000008839: {
    comapnyId: 7000008839,
    company_name: "E- DESIGNS",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000052667: {
    comapnyId: 7000052667,
    company_name: "SWARN DEEP",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000052565: {
    comapnyId: 7000052565,
    company_name: "NAVRITHI JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000015394: {
    comapnyId: 7000015394,
    company_name: "SHANTI GOLD INTERNATIONAL LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000010090: {
    comapnyId: 7000010090,
    company_name: "SRI NAVDURGA GEMS AND JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000006372: {
    comapnyId: 7000006372,
    company_name: "MAMRAJ MUSSADILAL JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000011439: {
    comapnyId: 7000011439,
    company_name: "SRI VENKATESHWARA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000007668: {
    comapnyId: 7000007668,
    company_name: "LUCKY JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000034402: {
    comapnyId: 7000034402,
    company_name: "DHYAAN JEWELS LLP.",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000013722: {
    comapnyId: 7000013722,
    company_name: "SUNIL GOLD INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000004802: {
    comapnyId: 7000004802,
    company_name: "PATEL GEMS AND JEWELLRY",
    region: "region_2",
    section: "sectionData_13",
    area: 12,
  },
  7000015451: {
    comapnyId: 7000015451,
    company_name: "SHINESHILPI JEWELLERS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000013413: {
    comapnyId: 7000013413,
    company_name: "MOON EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000015966: {
    comapnyId: 7000015966,
    company_name: "SUNDARAM CHAINS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000069975: {
    comapnyId: 7000069975,
    company_name: "CARBON CUBE JEWELS",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000001940: {
    comapnyId: 7000001940,
    company_name: "AADEY JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000063515: {
    comapnyId: 7000063515,
    company_name: "AASHIRWAD JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000006877: {
    comapnyId: 7000006877,
    company_name: "L.K. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000005211: {
    comapnyId: 7000005211,
    company_name: "ARIANNA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000003432: {
    comapnyId: 7000003432,
    company_name: "MRK GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000016573: {
    comapnyId: 7000016573,
    company_name: "VIJAYRAJ & SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000053578: {
    comapnyId: 7000053578,
    company_name: "RM PRECIOUS JEWELRY INDIA LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000017203: {
    comapnyId: 7000017203,
    company_name: "RAMNIKLAL & SONS",
    region: "region_3",
    section: "sectionData_1",
    area: 27,
  },
  7000000694: {
    comapnyId: 7000000694,
    company_name: "DHANDIA GEMS CORPORATION",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000014628: {
    comapnyId: 7000014628,
    company_name: "BIJOUX ARGENT PVT LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000066702: {
    comapnyId: 7000066702,
    company_name: "D N JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000019090: {
    comapnyId: 7000019090,
    company_name: "LIMELIGHT LAB GROWN DIAMONDS LIMITED",
    region: "region_2",
    section: "sectionData_7",
    area: 18,
  },
  7000011570: {
    comapnyId: 7000011570,
    company_name: "B G PLATINUM",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015505: {
    comapnyId: 7000015505,
    company_name: "SHREE DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000017006: {
    comapnyId: 7000017006,
    company_name: "GIA INDIA LABORATORY PVT. LTD.",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000011400: {
    comapnyId: 7000011400,
    company_name: "JUNAGADH JEWELLERY PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000050586: {
    comapnyId: 7000050586,
    company_name: "LEELA GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000007796: {
    comapnyId: 7000007796,
    company_name: "ASHOK JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000010781: {
    comapnyId: 7000010781,
    company_name: "ROMIL JEWELRY",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000000988: {
    comapnyId: 7000000988,
    company_name: "VANSH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000007503: {
    comapnyId: 7000007503,
    company_name: "LAXMI JEWELLERY EXPORT PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000007696: {
    comapnyId: 7000007696,
    company_name: "PM JEWELEX PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000002994: {
    comapnyId: 7000002994,
    company_name: "WHITE FIRE DIAMONDS INDIA PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000026269: {
    comapnyId: 7000026269,
    company_name: "VK JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000052664: {
    comapnyId: 7000052664,
    company_name: "RASHMINBHAI SHANTILAL ZINZUWADIA PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000068177: {
    comapnyId: 7000068177,
    company_name: "ASHOK JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000006017: {
    comapnyId: 7000006017,
    company_name: "M S DIAAGEM",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000065055: {
    comapnyId: 7000065055,
    company_name: "GLITIANI",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000044723: {
    comapnyId: 7000044723,
    company_name: "AMARKOSH JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000001441: {
    comapnyId: 7000001441,
    company_name: "C N B DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000061029: {
    comapnyId: 7000061029,
    company_name: "JOHRISONS JEWELLERS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000012672: {
    comapnyId: 7000012672,
    company_name: "BAHETI GEMS & JEWELS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000035489: {
    comapnyId: 7000035489,
    company_name: "DANTARA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000006090: {
    comapnyId: 7000006090,
    company_name: "G.P. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011702: {
    comapnyId: 7000011702,
    company_name: "RAINBOW",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000048332: {
    comapnyId: 7000048332,
    company_name: "PLATA ZONE",
    region: "region_1",
    section: "sectionData_4",
    area: 16,
  },
  7000037693: {
    comapnyId: 7000037693,
    company_name: "PGP JOYERIA LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000005955: {
    comapnyId: 7000005955,
    company_name: "S. JINDAL GEMS INDIA LLP",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000009801: {
    comapnyId: 7000009801,
    company_name: "ANJA JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000005717: {
    comapnyId: 7000005717,
    company_name: "MATUSHREE GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000001354: {
    comapnyId: 7000001354,
    company_name: "DUSAD & COMPANY",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000034760: {
    comapnyId: 7000034760,
    company_name: "SAHANARATNABH PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000008298: {
    comapnyId: 7000008298,
    company_name: "MEHTA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000058474: {
    comapnyId: 7000058474,
    company_name: "SILVER SHADES",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000017059: {
    comapnyId: 7000017059,
    company_name: "RANIWALA JEWELLERS PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000054188: {
    comapnyId: 7000054188,
    company_name: "RUMI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000015763: {
    comapnyId: 7000015763,
    company_name: "SONI NANALAL BECHARDAS JEWELLERS PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000002274: {
    comapnyId: 7000002274,
    company_name: "LAXMI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000016509: {
    comapnyId: 7000016509,
    company_name: "VASUPATI JEWELLERS INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015301: {
    comapnyId: 7000015301,
    company_name: "SEJAL EXPORTS (INDIA)",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000057630: {
    comapnyId: 7000057630,
    company_name: "ARIHANT JEWEL CRAFT",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000061153: {
    comapnyId: 7000061153,
    company_name: "ALPHA KOSMIK DESIGNS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000016555: {
    comapnyId: 7000016555,
    company_name: "VIJAY EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000037528: {
    comapnyId: 7000037528,
    company_name: "KANAK GEM & JEWELRY",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000047179: {
    comapnyId: 7000047179,
    company_name: "VIDHI JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000008377: {
    comapnyId: 7000008377,
    company_name: "MASTER CHAIN PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000057732: {
    comapnyId: 7000057732,
    company_name: "PURPLE JEWELS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000069888: {
    comapnyId: 7000069888,
    company_name: "VK PRECIOUS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_7",
    area: 18,
  },
  7000053269: {
    comapnyId: 7000053269,
    company_name: "RATIH JEWELS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000067023: {
    comapnyId: 7000067023,
    company_name: "VARNICA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000004566: {
    comapnyId: 7000004566,
    company_name: "STUTI JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000005993: {
    comapnyId: 7000005993,
    company_name: "SHRIPATI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000061087: {
    comapnyId: 7000061087,
    company_name: "KALAVATHI JEWELLERSS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000004155: {
    comapnyId: 7000004155,
    company_name: "ELVEE JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000000743: {
    comapnyId: 7000000743,
    company_name: "DHARMESH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000061277: {
    comapnyId: 7000061277,
    company_name: "DIAMONDS ON CALL INDIA LLP",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000017023: {
    comapnyId: 7000017023,
    company_name: "UTSSAV CZ GOLD JEWELS LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000011250: {
    comapnyId: 7000011250,
    company_name: "R K R GOLD PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000003361: {
    comapnyId: 7000003361,
    company_name: "MITAL JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000002857: {
    comapnyId: 7000002857,
    company_name: "SWARN SHANTI CZ GOLD JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013051: {
    comapnyId: 7000013051,
    company_name: "YASONI GOLDIAM PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000050756: {
    comapnyId: 7000050756,
    company_name: "SANGEETA BOOCHRA JAIPUR",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000059384: {
    comapnyId: 7000059384,
    company_name: "STAR MANGALSUTRA LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000034340: {
    comapnyId: 7000034340,
    company_name: "SUNSHINE CORPORATION",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000037282: {
    comapnyId: 7000037282,
    company_name: "SMR JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000015529: {
    comapnyId: 7000015529,
    company_name: "SHREE KALPTARU JEWELS (I) P.LT",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000000673: {
    comapnyId: 7000000673,
    company_name: "SOVAN JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000012486: {
    comapnyId: 7000012486,
    company_name: "RINKLE IMPEX",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000069887: {
    comapnyId: 7000069887,
    company_name: "AUREZA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000012171: {
    comapnyId: 7000012171,
    company_name: "SRI SHYAMBABA JEWELLERS PVT. LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000059388: {
    comapnyId: 7000059388,
    company_name: "SPARKLING CHAINS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000013731: {
    comapnyId: 7000013731,
    company_name: "KAKADIAM",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000038394: {
    comapnyId: 7000038394,
    company_name: "YAASHVI JEWELLERS PVT LTD",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000012863: {
    comapnyId: 7000012863,
    company_name: "RUCHI EXPORTS",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000008743: {
    comapnyId: 7000008743,
    company_name: "BHAGWATI IMPEX",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000051050: {
    comapnyId: 7000051050,
    company_name: "SHREE BALAJI CHAIN & JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000006116: {
    comapnyId: 7000006116,
    company_name: "JMD JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000037954: {
    comapnyId: 7000037954,
    company_name: "OPH JEWELLER",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000005738: {
    comapnyId: 7000005738,
    company_name: "MANAPPURAM JEWELLERS LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000051909: {
    comapnyId: 7000051909,
    company_name: "SHASHWAT ORNAMENTS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000002192: {
    comapnyId: 7000002192,
    company_name: "NAKODA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000068064: {
    comapnyId: 7000068064,
    company_name: "SILVER SOURCE",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000005327: {
    comapnyId: 7000005327,
    company_name: "VAMA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000008326: {
    comapnyId: 7000008326,
    company_name: "MANUBHAI ZAVERI ORNAMENTS PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000013190: {
    comapnyId: 7000013190,
    company_name: "PRIDE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000053927: {
    comapnyId: 7000053927,
    company_name: "RAJENDRA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000052820: {
    comapnyId: 7000052820,
    company_name: "BERRY GEMS GALLERY",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000067432: {
    comapnyId: 7000067432,
    company_name: "VIYA GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000066367: {
    comapnyId: 7000066367,
    company_name: "RATNAWALI",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000016071: {
    comapnyId: 7000016071,
    company_name: "SWARNSHILP CHAINS & JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000053139: {
    comapnyId: 7000053139,
    company_name: "SUNDEEP JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000052407: {
    comapnyId: 7000052407,
    company_name: "RUSHABHVINITLABDHIJEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000068072: {
    comapnyId: 7000068072,
    company_name: "AMYRAH CREATIONS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000009083: {
    comapnyId: 7000009083,
    company_name: "MUKTI GOLD PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000063033: {
    comapnyId: 7000063033,
    company_name: "SILVREDGE LLP",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000069512: {
    comapnyId: 7000069512,
    company_name: "RAJASTHAN SILVER WORKS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000058649: {
    comapnyId: 7000058649,
    company_name: "B. D. JEWELLS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000049992: {
    comapnyId: 7000049992,
    company_name: "VARDHMANKRUPA GOLD PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000037542: {
    comapnyId: 7000037542,
    company_name: "M R JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000070491: {
    comapnyId: 7000070491,
    company_name: "ARIHANT GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000070641: {
    comapnyId: 7000070641,
    company_name: "KJ S FEATHER TOUCH JEWELLERY PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000066647: {
    comapnyId: 7000066647,
    company_name: "NAMOSHREE GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000012437: {
    comapnyId: 7000012437,
    company_name: "OM ALANKAR JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000070581: {
    comapnyId: 7000070581,
    company_name: "SURESH GARG GEMS LLP",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000060741: {
    comapnyId: 7000060741,
    company_name: "GPK GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000010064: {
    comapnyId: 7000010064,
    company_name: "MANMOHAN EXPORTS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000001138: {
    comapnyId: 7000001138,
    company_name: "MANGALAM GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000077016: {
    comapnyId: 7000077016,
    company_name: "MEERA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000071274: {
    comapnyId: 7000071274,
    company_name: "KRIZORA CREATIONS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000076431: {
    comapnyId: 7000076431,
    company_name: "INTERNATIONAL INSTITUTE OF GEMOLOGY SOUTH",
    region: "region_1",
    section: "sectionData_5",
    area: 9,
  },
  7000017020: {
    comapnyId: 7000017020,
    company_name: "RBZ JEWELLERS LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000025490: {
    comapnyId: 7000025490,
    company_name: "INDERLAL BALMUKAND",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000070305: {
    comapnyId: 7000070305,
    company_name: "ROYAL ANGEL PEARL GEM JEWELS LLP",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000066436: {
    comapnyId: 7000066436,
    company_name: "ROSE JEWELS INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000070012: {
    comapnyId: 7000070012,
    company_name: "VAIBHAV CREATIONS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000020104: {
    comapnyId: 7000020104,
    company_name: "BANETHI EXPORTS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000003587: {
    comapnyId: 7000003587,
    company_name: "HEENA IMPEX",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000074170: {
    comapnyId: 7000074170,
    company_name: "VENUS CREATION",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000008203: {
    comapnyId: 7000008203,
    company_name: "MANI JEWEL",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000008266: {
    comapnyId: 7000008266,
    company_name: "ASTMANGAL CHAINS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000003277: {
    comapnyId: 7000003277,
    company_name: "RAJ DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000007815: {
    comapnyId: 7000007815,
    company_name: "SHUBH ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000001109: {
    comapnyId: 7000001109,
    company_name: "R. V. ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000015661: {
    comapnyId: 7000015661,
    company_name: "SILVER EMPORIUM PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000000791: {
    comapnyId: 7000000791,
    company_name: "MANJALLY JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000066336: {
    comapnyId: 7000066336,
    company_name: "SILVER EMPORIUM ARTE LLP.",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000009684: {
    comapnyId: 7000009684,
    company_name: "LAXMI MANUFACTURE",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075469: {
    comapnyId: 7000075469,
    company_name: "SURANA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000044229: {
    comapnyId: 7000044229,
    company_name: "GOLD STAR ELITE JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000067575: {
    comapnyId: 7000067575,
    company_name: "STAR JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015612: {
    comapnyId: 7000015612,
    company_name: "SHUBHAM MOTIWALA & JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000016961: {
    comapnyId: 7000016961,
    company_name: "CHAIN N CHAINS JEWELS LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000060988: {
    comapnyId: 7000060988,
    company_name: "SAIFUDDIN ARTISTIC GOLD JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010456: {
    comapnyId: 7000010456,
    company_name: "SRI MARUTI JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000057271: {
    comapnyId: 7000057271,
    company_name: "NAVAM SUNIL JEWELLERS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000014838: {
    comapnyId: 7000014838,
    company_name: "DEW DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000057182: {
    comapnyId: 7000057182,
    company_name: "P. K. JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000057279: {
    comapnyId: 7000057279,
    company_name: "VARNI JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000061576: {
    comapnyId: 7000061576,
    company_name: "MD GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075548: {
    comapnyId: 7000075548,
    company_name: "DASHRATH GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000057629: {
    comapnyId: 7000057629,
    company_name: "SREE CHAMUNDI JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000000434: {
    comapnyId: 7000000434,
    company_name: "DASSANI BROTHERS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000016617: {
    comapnyId: 7000016617,
    company_name: "VIRAASAT JEWELS PVT. LTD.",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000075449: {
    comapnyId: 7000075449,
    company_name: "RAMBALRAAM CHAINSS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_4",
    area: 9,
  },
  7000064504: {
    comapnyId: 7000064504,
    company_name: "SKJGOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000075394: {
    comapnyId: 7000075394,
    company_name: "AMOGH JEWELS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000069707: {
    comapnyId: 7000069707,
    company_name: "MIRON ROY JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000067257: {
    comapnyId: 7000067257,
    company_name: "MJR SILVER PVT LTD",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000060590: {
    comapnyId: 7000060590,
    company_name: "SHREE VASAVI GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000011792: {
    comapnyId: 7000011792,
    company_name: "LAKHTARWALA JEWELLERS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000008650: {
    comapnyId: 7000008650,
    company_name: "SOUTH INDIA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011519: {
    comapnyId: 7000011519,
    company_name: "R.P. ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000075511: {
    comapnyId: 7000075511,
    company_name: "ROYAL CREATIONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069624: {
    comapnyId: 7000069624,
    company_name: "BHAKTI JEWELS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000007797: {
    comapnyId: 7000007797,
    company_name: "ANMOL JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 27,
  },
  7000060995: {
    comapnyId: 7000060995,
    company_name: "YASHIKA DIAMONDS PVT. LTD.",
    region: "region_6",
    section: "sectionData_1",
    area: 27,
  },
  7000015197: {
    comapnyId: 7000015197,
    company_name: "SANT RAM MANGAT RAM JEWELLERS PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 12,
  },
  7000058859: {
    comapnyId: 7000058859,
    company_name: "KUMKUM JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000059791: {
    comapnyId: 7000059791,
    company_name: "RVJ JEWELLERS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000053166: {
    comapnyId: 7000053166,
    company_name: "LOKHESHH JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000058212: {
    comapnyId: 7000058212,
    company_name: "JEWELS VALLEY",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000005762: {
    comapnyId: 7000005762,
    company_name: "GOLDEN MOMENTS JEWELLERY",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000035666: {
    comapnyId: 7000035666,
    company_name: "ECO LIGHT DIAMOND",
    region: "region_2",
    section: "sectionData_7",
    area: 9,
  },
  7000075609: {
    comapnyId: 7000075609,
    company_name: "SHRI BANU DIAMOND",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000059151: {
    comapnyId: 7000059151,
    company_name: "MITTAL PEARLS AND JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000012008: {
    comapnyId: 7000012008,
    company_name: "SHAKTI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000057542: {
    comapnyId: 7000057542,
    company_name: "KK ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000066893: {
    comapnyId: 7000066893,
    company_name: "ILA CREATION PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000057607: {
    comapnyId: 7000057607,
    company_name: "HEERA JEWELS AND GEMS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000075841: {
    comapnyId: 7000075841,
    company_name: "ALLURE PEARLS AND JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000053530: {
    comapnyId: 7000053530,
    company_name: "CREATIVE GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011815: {
    comapnyId: 7000011815,
    company_name: "S M GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000075902: {
    comapnyId: 7000075902,
    company_name: "PLUTUSJEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000075863: {
    comapnyId: 7000075863,
    company_name: "VANIOR JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_7",
    area: 9,
  },
  7000054652: {
    comapnyId: 7000054652,
    company_name: "SRI KRUPA JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000075900: {
    comapnyId: 7000075900,
    company_name: "SATVA GOLD LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000000812: {
    comapnyId: 7000000812,
    company_name: "DIA JEWEL",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000000021: {
    comapnyId: 7000000021,
    company_name: "N.J. GEMS",
    region: "region_2",
    section: "sectionData_7",
    area: 12,
  },
  7000074560: {
    comapnyId: 7000074560,
    company_name: "MOHAR GEMS AND JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000015249: {
    comapnyId: 7000015249,
    company_name: "SHREE FINE JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008239: {
    comapnyId: 7000008239,
    company_name: "S K JORA JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000070754: {
    comapnyId: 7000070754,
    company_name: "M B DEESIGNS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000000034: {
    comapnyId: 7000000034,
    company_name: "BHAGWATI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000011089: {
    comapnyId: 7000011089,
    company_name: "ABDHI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000012955: {
    comapnyId: 7000012955,
    company_name: "GANESH DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000000321: {
    comapnyId: 7000000321,
    company_name: "PALSANI JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 18,
  },
  7000014916: {
    comapnyId: 7000014916,
    company_name: "SHREE BHOMIYA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000005248: {
    comapnyId: 7000005248,
    company_name: "JIGNA CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000063261: {
    comapnyId: 7000063261,
    company_name: "GANESH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013115: {
    comapnyId: 7000013115,
    company_name: "S.A. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000049060: {
    comapnyId: 7000049060,
    company_name: "SHREE NAKODA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075402: {
    comapnyId: 7000075402,
    company_name: "OMKALASH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000051855: {
    comapnyId: 7000051855,
    company_name: "MAWSIM GOLD PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000066857: {
    comapnyId: 7000066857,
    company_name: "DARSHAN GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000060465: {
    comapnyId: 7000060465,
    company_name: "ATMIYA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000069908: {
    comapnyId: 7000069908,
    company_name: "MTJ WORLD INDIA PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000076246: {
    comapnyId: 7000076246,
    company_name: "SHREE H P JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000051622: {
    comapnyId: 7000051622,
    company_name: "FIONAA GOLD PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000010467: {
    comapnyId: 7000010467,
    company_name: "KOVAI SILVER PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000069668: {
    comapnyId: 7000069668,
    company_name: "SHRI VALLABHASHRAY ART JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000076586: {
    comapnyId: 7000076586,
    company_name: "SUVIDHI ORNA PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000067228: {
    comapnyId: 7000067228,
    company_name: "BOOK MY DIAMOND PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000057768: {
    comapnyId: 7000057768,
    company_name: "FAB GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000067273: {
    comapnyId: 7000067273,
    company_name: "SRK JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000000935: {
    comapnyId: 7000000935,
    company_name: "ROSE JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000012420: {
    comapnyId: 7000012420,
    company_name: "KOMAL EXPORTS",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000063696: {
    comapnyId: 7000063696,
    company_name: "S P GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000066135: {
    comapnyId: 7000066135,
    company_name: "AADYA CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000035736: {
    comapnyId: 7000035736,
    company_name: "VIDH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066479: {
    comapnyId: 7000066479,
    company_name: "ANIKA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000034745: {
    comapnyId: 7000034745,
    company_name: "HIRANYA INDIA JEWEL PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000057615: {
    comapnyId: 7000057615,
    company_name: "ZORBA DIAMOND PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000000418: {
    comapnyId: 7000000418,
    company_name: "MANOJ ORNAMENTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000016864: {
    comapnyId: 7000016864,
    company_name: "KAMAL NARNOLI JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000076966: {
    comapnyId: 7000076966,
    company_name: "QUBE STAR",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000015690: {
    comapnyId: 7000015690,
    company_name: "SIMLA` S JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000053968: {
    comapnyId: 7000053968,
    company_name: "RISHITH GEMS & JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000070142: {
    comapnyId: 7000070142,
    company_name: "AVSAR GOLD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000019034: {
    comapnyId: 7000019034,
    company_name: "M.AKSHITKUMAR & CO",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000060808: {
    comapnyId: 7000060808,
    company_name: "P D SONI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000003941: {
    comapnyId: 7000003941,
    company_name: "V.D. JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000016784: {
    comapnyId: 7000016784,
    company_name: "KANCHAN ANTIQUE GOLD JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000070137: {
    comapnyId: 7000070137,
    company_name: "JAYAMBE JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000001630: {
    comapnyId: 7000001630,
    company_name: "FINESTAR JEWELLERY AND DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000077454: {
    comapnyId: 7000077454,
    company_name: "GOVARDHANNATH JEWELERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000063178: {
    comapnyId: 7000063178,
    company_name: "TISTABENE ENTERPRISES PVT LTD",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000076240: {
    comapnyId: 7000076240,
    company_name: "PR AGARWAL JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000077224: {
    comapnyId: 7000077224,
    company_name: "MAHAVEER SILVER HOUSE",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000038087: {
    comapnyId: 7000038087,
    company_name: "SWARNA SHILPI",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000067327: {
    comapnyId: 7000067327,
    company_name: "TAPERED JEWELS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000069973: {
    comapnyId: 7000069973,
    company_name: "R K SONI AND SONS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000069849: {
    comapnyId: 7000069849,
    company_name: "SHREE JOYERIA",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000004693: {
    comapnyId: 7000004693,
    company_name: "JAYANTILAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000050651: {
    comapnyId: 7000050651,
    company_name: "JAI BHAIRAVA GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000066718: {
    comapnyId: 7000066718,
    company_name: "S.V.GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000017057: {
    comapnyId: 7000017057,
    company_name: "BIKANERI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000050618: {
    comapnyId: 7000050618,
    company_name: "MY CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000070574: {
    comapnyId: 7000070574,
    company_name: "AIRAVATA HERITAGE JEWELLERY",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000004982: {
    comapnyId: 7000004982,
    company_name: "SHUBH ART JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000009048: {
    comapnyId: 7000009048,
    company_name: "LOVE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000001514: {
    comapnyId: 7000001514,
    company_name: "EARTH STONE INC",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000075371: {
    comapnyId: 7000075371,
    company_name: "GAYATRI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069750: {
    comapnyId: 7000069750,
    company_name: "APARAJITAA JEWEL",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000075967: {
    comapnyId: 7000075967,
    company_name: "DESIGN ESCLUSIVO JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000007785: {
    comapnyId: 7000007785,
    company_name: "TIBARUMAL RAMNIVAS GEMS JEWELS & PEARLS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000006975: {
    comapnyId: 7000006975,
    company_name: "NARAYAN GEMS",
    region: "region_2",
    section: "sectionData_13",
    area: 9,
  },
  7000052963: {
    comapnyId: 7000052963,
    company_name: "SHANTILAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000002168: {
    comapnyId: 7000002168,
    company_name: "NAVARATNA MAALIGAI",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000012136: {
    comapnyId: 7000012136,
    company_name: "JINKUSHAL JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 12,
  },
  7000070312: {
    comapnyId: 7000070312,
    company_name: "KIRA DIAM LLP",
    region: "region_2",
    section: "sectionData_7",
    area: 36,
  },
  7000061093: {
    comapnyId: 7000061093,
    company_name: "PODDAR JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000007118: {
    comapnyId: 7000007118,
    company_name: "ANKIT GOLD LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000067646: {
    comapnyId: 7000067646,
    company_name: "MAHAVEER CHAIN & C. Z.",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000001306: {
    comapnyId: 7000001306,
    company_name: "RATAN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000002065: {
    comapnyId: 7000002065,
    company_name: "ALPSTAR DESIGNER",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000075687: {
    comapnyId: 7000075687,
    company_name: "YOGI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000078368: {
    comapnyId: 7000078368,
    company_name: "AVTAR JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000078359: {
    comapnyId: 7000078359,
    company_name: "SRI KANNIGHAA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000061174: {
    comapnyId: 7000061174,
    company_name: "V.K. JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000002306: {
    comapnyId: 7000002306,
    company_name: "VEE J JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000076589: {
    comapnyId: 7000076589,
    company_name: "JBC JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000011418: {
    comapnyId: 7000011418,
    company_name: "R.B. CHAINS",
    region: "region_6",
    section: "sectionData_4",
    area: 9,
  },
  7000037447: {
    comapnyId: 7000037447,
    company_name: "SHANTINATH PEARLS",
    region: "region_2",
    section: "sectionData_13",
    area: 9,
  },
  7000070695: {
    comapnyId: 7000070695,
    company_name: "SHREEJI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000017243: {
    comapnyId: 7000017243,
    company_name: "STAR JEWELLERY",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000009731: {
    comapnyId: 7000009731,
    company_name: "KANAK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000077640: {
    comapnyId: 7000077640,
    company_name: "BHAKTI LAB GROWN DIAMOND PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000062025: {
    comapnyId: 7000062025,
    company_name: "EMERGEDIAM LLP",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000066553: {
    comapnyId: 7000066553,
    company_name: "CHAINSKART",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000003571: {
    comapnyId: 7000003571,
    company_name: "DIVYA JEWELS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000070347: {
    comapnyId: 7000070347,
    company_name: "M S GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000067700: {
    comapnyId: 7000067700,
    company_name: "MADHAVAN ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000052904: {
    comapnyId: 7000052904,
    company_name: "PROMISE JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000070256: {
    comapnyId: 7000070256,
    company_name: "SAMARTH JEWELLERY CREATIONS PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000069607: {
    comapnyId: 7000069607,
    company_name: "LEO SILVER ART PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000009393: {
    comapnyId: 7000009393,
    company_name: "SHRI NATH ABHUSHAN BHANDAR",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000069839: {
    comapnyId: 7000069839,
    company_name: "RIYA GEMS",
    region: "region_4",
    section: "sectionData_13",
    area: 9,
  },
  7000069990: {
    comapnyId: 7000069990,
    company_name: "ATUL JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000070690: {
    comapnyId: 7000070690,
    company_name: "SUNRISE AURO & DIAMOND PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000067686: {
    comapnyId: 7000067686,
    company_name: "SHARJAH AIRPORT INTERNATIONAL FREE ZONE AUTHORITY",
    region: "region_2",
    section: "sectionData_8",
    area: 18,
  },
  7000078377: {
    comapnyId: 7000078377,
    company_name: "KESHAV JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 12,
  },
  7000046160: {
    comapnyId: 7000046160,
    company_name: "RAJLAXMI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000053102: {
    comapnyId: 7000053102,
    company_name: "ASHOK DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000015047: {
    comapnyId: 7000015047,
    company_name: "R.K. SILVER",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000013898: {
    comapnyId: 7000013898,
    company_name: "AKSHAR IMPEX PVT. LTD.",
    region: "region_2",
    section: "sectionData_6",
    area: 9,
  },
  7000078389: {
    comapnyId: 7000078389,
    company_name: "PALLAV JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000054325: {
    comapnyId: 7000054325,
    company_name: "GOLDEN CARAT PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000078372: {
    comapnyId: 7000078372,
    company_name: "R B ORNAMENT",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000009276: {
    comapnyId: 7000009276,
    company_name: "SHRINGAR HOUSE OF MANGALSUTRA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000078369: {
    comapnyId: 7000078369,
    company_name: "SHRI PADMAVATHI GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000068574: {
    comapnyId: 7000068574,
    company_name: "SWAADHA JEWEL LLP",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
};

const _data = {
  // 7000014408: {
  //   comapnyId: 7000014408,
  //   company_name: "BEAUTY GEMS & JEWELLERY",
  //   region: "region_4",
  //   section: "sectionData_2",
  //   area: 9,
  // },
  // 7000052597: {
  //   comapnyId: 7000052597,
  //   company_name: "SUNIL GEMS AND JEWELLERY PVT. LTD",
  //   region: "region_4",
  //   section: "sectionData_1",
  //   area: 36,
  // },
  // 7000038024: {
  //   comapnyId: 7000038024,
  //   company_name: "ACPL CHAINS LLP",
  //   region: "region_6",
  //   section: "sectionData_4",
  //   area: 9,
  // },
  // 7000066499: {
  //   comapnyId: 7000066499,
  //   company_name: "KATTAS GEMS & JEWELS",
  //   region: "region_4",
  //   section: "sectionData_4",
  //   area: 12,
  // },
  // 7000078370: {
  //   comapnyId: 7000078370,
  //   company_name: "JAIPUR GEMS PARADISE",
  //   region: "region_1",
  //   section: "sectionData_13",
  //   area: 12,
  // },
  // 7000075993: {
  //   comapnyId: 7000075993,
  //   company_name: "KEDIA JEWELS",
  //   region: "region_4",
  //   section: "sectionData_1",
  //   area: 9,
  // },
  // 7000052949: {
  //   comapnyId: 7000052949,
  //   company_name: "PADMAWATI GEMS AND JEWELLERY LLP",
  //   region: "region_1",
  //   section: "sectionData_1",
  //   area: 9,
  // },
  // 7000038389: {
  //   comapnyId: 7000038389,
  //   company_name: "RAJMANI JEWELS & CO.",
  //   region: "region_6",
  //   section: "sectionData_1",
  //   area: 9,
  // },

  // 7000009270: {
  //   comapnyId: 7000009270,
  //   company_name: "PIERCING SYSTEMS INDIA PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000037828: {
  //   comapnyId: 7000037828,
  //   company_name: "KHUSHBOO NOVELTIES",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000048444: {
  //   comapnyId: 7000048444,
  //   company_name: "MANOHAR LAL AND SONS",
  //   region: "region_4",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000065267: {
  //   comapnyId: 7000065267,
  //   company_name: "SIONIQ TECH PRIVATE LIMITED",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000034346: {
  //   comapnyId: 7000034346,
  //   company_name: "DM INVENT PRIVATE LIMITED",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000078546: {
  //   comapnyId: 7000078546,
  //   company_name: "KARATGAUGE",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000059757: {
  //   comapnyId: 7000059757,
  //   company_name: "DIAMTECH RESEARCH CENTRE PVT LTD",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000069945: {
  //   comapnyId: 7000069945,
  //   company_name: "IGNITE INDUCTION LLP",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000017415: {
  //   comapnyId: 7000017415,
  //   company_name: "ACME INFOVISION SYSTEMS PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000003168: {
  //   comapnyId: 7000003168,
  //   company_name: "RAJESH ENTERPRISES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000002071: {
  //   comapnyId: 7000002071,
  //   company_name: "NUCLEUS ANALYTICS PRIVATE LIMITED",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000055951: {
  //   comapnyId: 7000055951,
  //   company_name: "RESHMA ENTERPRISES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000068391: {
  //   comapnyId: 7000068391,
  //   company_name: "AJAHARA PACKAGING (OPC) PVT LTD",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000013110: {
  //   comapnyId: 7000013110,
  //   company_name: "CASCADE STAR INDIA PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000047292: {
  //   comapnyId: 7000047292,
  //   company_name: "MAGNIC TECHNOLOGIES PVT LTD",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000066835: {
  //   comapnyId: 7000066835,
  //   company_name: "PRECISE PIERCING PRODUCTS INDIA LLP",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000004626: {
  //   comapnyId: 7000004626,
  //   company_name: "ASHLYN CHEMUNNOOR INSTRUMENTS PVT LTD",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000034767: {
  //   comapnyId: 7000034767,
  //   company_name: "UNITED PMR INC",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000048150: {
  //   comapnyId: 7000048150,
  //   company_name: "J.T.E",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000052731: {
  //   comapnyId: 7000052731,
  //   company_name: "ENDORA INTERIORS",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000002258: {
  //   comapnyId: 7000002258,
  //   company_name: "P K TRADERS",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 36,
  // },
  // 7000057421: {
  //   comapnyId: 7000057421,
  //   company_name: "MAKE3D.IN",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000063294: {
  //   comapnyId: 7000063294,
  //   company_name: "LUCKY TRADERS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000005306: {
  //   comapnyId: 7000005306,
  //   company_name: "IQTEK SOFTWARE LTD",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000069023: {
  //   comapnyId: 7000069023,
  //   company_name: "WJT INDIA PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000015237: {
  //   comapnyId: 7000015237,
  //   company_name: "SARTO ELECTRO EQUIPMENT PVT.LT D.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000060985: {
  //   comapnyId: 7000060985,
  //   company_name: "J R LABELWALA AND CO",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000017255: {
  //   comapnyId: 7000017255,
  //   company_name: "ACZET PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000009464: {
  //   comapnyId: 7000009464,
  //   company_name: "NAVBHARAT CARBON CO. (BOMBAY)",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000053153: {
  //   comapnyId: 7000053153,
  //   company_name: "INVIZIO SOLUTIONS LLP",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000054675: {
  //   comapnyId: 7000054675,
  //   company_name: "BOSON PACKAGING",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000009375: {
  //   comapnyId: 7000009375,
  //   company_name: "PACIO PACK LLP",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 36,
  // },
  // 7000017378: {
  //   comapnyId: 7000017378,
  //   company_name: "EAGLE INDUSTRIES",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000017380: {
  //   comapnyId: 7000017380,
  //   company_name: "D SOFT INFOTECH PVT LTD",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078547: {
  //   comapnyId: 7000078547,
  //   company_name: "ADITYA SYSTEMS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000010992: {
  //   comapnyId: 7000010992,
  //   company_name: "MILLENIA TECHNOLOGIES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000017280: {
  //   comapnyId: 7000017280,
  //   company_name: "MADHUSHREE NOVELTIES",
  //   region: "RO-KOL",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000060616: {
  //   comapnyId: 7000060616,
  //   company_name: "SHREE RAJ MARKETING",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 27,
  // },
  // 7000017321: {
  //   comapnyId: 7000017321,
  //   company_name: "S.R. MEHTA & SONS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000004101: {
  //   comapnyId: 7000004101,
  //   company_name: "SACHI",
  //   region: "region_4",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000069529: {
  //   comapnyId: 7000069529,
  //   company_name: "SHREE RAPID TECHNOLOGIES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000017323: {
  //   comapnyId: 7000017323,
  //   company_name: "GESSWEIN TRADING PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000017488: {
  //   comapnyId: 7000017488,
  //   company_name: "FISCHER MEASUREMENT TECHNOLOGI ES (INDIA) PVT LTD",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000009167: {
  //   comapnyId: 7000009167,
  //   company_name: "ARIHANT MAXSELL TECHNOLOGIES PVT LTD",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000061516: {
  //   comapnyId: 7000061516,
  //   company_name: "TISHYA CNC ENGINNERING WORKS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000053155: {
  //   comapnyId: 7000053155,
  //   company_name: "AONE DIAMOND AIDS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000069955: {
  //   comapnyId: 7000069955,
  //   company_name: "ALPHA E BARCODE SOLUTIONS PVT LTD",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000071493: {
  //   comapnyId: 7000071493,
  //   company_name: "DOIT DIGIFABB INDIA PRIVATE LIMITED",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000057707: {
  //   comapnyId: 7000057707,
  //   company_name: "MRK INNOAPPS PRIVATE LIMITED",
  //   region: "region_1",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000075842: {
  //   comapnyId: 7000075842,
  //   company_name: "JKGOLD CHEMICALS PRIVATE LIMITED",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000053258: {
  //   comapnyId: 7000053258,
  //   company_name: "PARSHNATH BOXES",
  //   region: "region_6",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000066398: {
  //   comapnyId: 7000066398,
  //   company_name: "INSTA LAXMI PRIVATE LIMITED",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000052450: {
  //   comapnyId: 7000052450,
  //   company_name: "HEMNAV VENTURES PRIVATE LIMITED",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000075915: {
  //   comapnyId: 7000075915,
  //   company_name: "FUNDWISOR FINANCIALS LLP",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000044376: {
  //   comapnyId: 7000044376,
  //   company_name: "SHAPET INDUCTION COMPANY",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000067483: {
  //   comapnyId: 7000067483,
  //   company_name: "SPECTROVISION INTRUMENTS PVT LTD",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000044532: {
  //   comapnyId: 7000044532,
  //   company_name: "MODERN JEWELLERY TOOLS",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000017360: {
  //   comapnyId: 7000017360,
  //   company_name: "GOLD SIDE PRODUCTION",
  //   region: "region_3",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078548: {
  //   comapnyId: 7000078548,
  //   company_name: "LOYAL STRING INTERNATIONAL PVT LTD",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000004772: {
  //   comapnyId: 7000004772,
  //   company_name: "MODERN CHAIN MACHINES",
  //   region: "region_6",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000059572: {
  //   comapnyId: 7000059572,
  //   company_name: "RETAIL JEWELLER INDIA",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000007035: {
  //   comapnyId: 7000007035,
  //   company_name: "INDIGO PRINTS PVT. LTD.",
  //   region: "region_6",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000061513: {
  //   comapnyId: 7000061513,
  //   company_name: "N. K. INDUSTRIES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078633: {
  //   comapnyId: 7000078633,
  //   company_name: "J TECH",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078708: {
  //   comapnyId: 7000078708,
  //   company_name: "PICUP MEDIA",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
  // 7000078601: {
  //   comapnyId: 7000078601,
  //   company_name: "SINDHAV INDUSTRIES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000004916: {
  //   comapnyId: 7000004916,
  //   company_name: "ARC DISTRIBUTORS (I) PVT. LTD.",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000025326: {
  //   comapnyId: 7000025326,
  //   company_name: "GDCC EXIM",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078635: {
  //   comapnyId: 7000078635,
  //   company_name: "UNICORN INDUSTRIES",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000044405: {
  //   comapnyId: 7000044405,
  //   company_name: "ITALIMPIANTI ORAFI SPA",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 9,
  // },
  // 7000078707: {
  //   comapnyId: 7000078707,
  //   company_name: "G B AUTOMATION",
  //   region: "region_2",
  //   section: "sectionData_12",
  //   area: 18,
  // },
};
export const setSellersBaseData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      // const snapshot = realDB.ref('/2022_sprint_3/test');
      await snapshot.update(newData);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

// console.log(data);

export const setNode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      await snapshot.update(_data);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const updateExhibitorData = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      await snapshot.update(data);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

window.setSellersBaseData = setSellersBaseData;
window.setNode = setNode;
