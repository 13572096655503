import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SearchContext } from '../../Context/SearchContextProvider';
import { realDB } from '../../firebase';
import FloorMap from '../../floorMap';
import StallPopUp, { MboileStallPopUp, MboileStallPopUp_NoInfo, StallPopUpNoInfo } from '../StallPopUp';
import CustomTransformWrapper from '../TransformWrapper';
import style from "./index.module.css";
import { isMobile } from 'react-device-detect';
import { AddClassNameToElement } from '../../util';
import { HallLayoutData } from '../../Constant/LayoutData';
import { HALL_KEYS_CONSTANT_DATA } from '../../Constant/AreaOptionData';
import { REALDB_CONSTANT } from '../../Constant/FirebaseConstant';
import { SectionDataColor } from '../../Constant/SectionData';

export default function FloorPlanContainer() {

    const { _data, updateSellersData, zoomToElementRef, stallId, setStallId, calculateRectFun, areaFilter, sectionFilter } = useContext(SearchContext);

    const svgContainerRef = useRef(null)
    const areaFilterRef = useRef(areaFilter)
    // useEffect(() => {
    //     areaFilterRef.current = areaFilter;
    //     let TEXT_EL = document.querySelector(`#TEXT22`)
    //     if (TEXT_EL) {
    //         if (areaFilter) {
    //             TEXT_EL.classList.add("hide_txt")
    //         } else {
    //             if (TEXT_EL.classList.contains("hide_txt"))
    //                 TEXT_EL.classList.remove("hide_txt")
    //         }
    //     }
    // }, [areaFilter])

    const sectionFilterRef = useRef(sectionFilter)
    useEffect(() => {
        areaFilterRef.current = areaFilter;
        sectionFilterRef.current = sectionFilter;
        let TEXT_EL = document.querySelector(`#TEXT`)
        if (TEXT_EL) {
            if (sectionFilter || areaFilter) {
                TEXT_EL.classList.add("hide_txt")
            } else {
                if (TEXT_EL.classList.contains("hide_txt"))
                    TEXT_EL.classList.remove("hide_txt")
            }
        } else {
            console.error("kidher hai text");
        }


        // let igmebg =  document.querySelector("#igmebg")
        // let diamondbg =  document.querySelector("#hidden")
        
        // if(sectionFilter){
        //     if(igmebg){
        //         if(sectionFilter =="sectionData_14"){
        //       igmebg.style.display = "block"
        //     }else{
        //         igmebg.style.display = "none"
        //     }


        //     if(diamondbg){
        //         if(sectionFilter =="sectionData_2"){
        //       diamondbg.style.display = "block"
        //     }else{
        //         diamondbg.style.display = "none"
        //     }
        // }
            
            
        // }     
        // }else{
        //     igmebg.style.display = "block"
        //     diamondbg.style.display = "block"
        // }
    }, [areaFilter, sectionFilter])


    const createDiv = (id, rect) => {
        let _checkdiv = document.querySelector(`#${id}_placeholder`)
        if (_checkdiv) {
            _checkdiv.remove();
        }
        let svg_rect = svgContainerRef.current.getBoundingClientRect();
        let _div = document.createElement("div");
        _div.id = id + "_placeholder";
        _div.style.top = `${rect.top - svg_rect.top}px`;
        _div.style.left = `${rect.left}px`;
        let _placeholders = document.querySelector(".ph_div");
        _placeholders.appendChild(_div);
    }

    useEffect(() => {
        // const calExce = (_stall) => {
        //     let rect = _stall.getBoundingClientRect();
        //     let svg_rect = svgContainerRef.current.getBoundingClientRect();
        //     // console.log(rect)
        //     // console.log(rect.left , (rect.width / 2) , svg_rect.left)
        //     let extraAddition = 0
        //     if (svg_rect.top > rect.top) {
        //         extraAddition = svg_rect.top - rect.top
        //     }
        //     let pos = {
        //         x: rect.left, //- (rect.width / 2) - svg_rect.left,
        //         y: rect.top + extraAddition, //- (rect.height) - 10 - svg_rect.top
        //     }
        //     // if (createDiv) {
        //     //     createDiv(id, rect)
        //     // }
        //     _data.current.stallData[id].pos = pos
        // }
        // const calculateRect = (id) => {
        //     let _stall = document.querySelector(`#${id}`);
        //     calExce(_stall);
        // }
        calculateRectFun.current = calculateRect;
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.JWCC].forEach(hd => processStallId_raw(hd))
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.h1].forEach(hd => processStallId_raw(hd))
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.h2].forEach(hd => processStallId_raw(hd))
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.h3].forEach(hd => processStallId_raw(hd))
        HallLayoutData[HALL_KEYS_CONSTANT_DATA.h4].forEach(hd => processStallId_raw(hd))
        HallLayoutData[HALL_KEYS_CONSTANT_DATA.h5].forEach(hd => processStallId_raw(hd))
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.h6].forEach(hd => processStallId_raw(hd))
        // HallLayoutData[HALL_KEYS_CONSTANT_DATA.h7].forEach(hd => processStallId_raw(hd))
        // AddClassNameToElement(HallLayoutData[HALL_KEYS_CONSTANT_DATA.h4], (el) => {

        // });
        // AddClassNameToElement(HallLayoutData[HALL_KEYS_CONSTANT_DATA.h6], (el) => {

        // });

        // remove the older one if exits
        realDB.ref(REALDB_CONSTANT.Sellersdata).on("child_added", (snaapshot) => {
            // console.log("s_ca")
            if (snaapshot.exists()) {
                processSellerData(snaapshot.val())
            }
        });
        realDB.ref(REALDB_CONSTANT.Sellersdata).on("child_changed", (snaapshot) => {
            // console.log("s_cc")
            if (snaapshot.exists()) {
                processSellerData(snaapshot.val())
            }
        });
        realDB.ref(REALDB_CONSTANT.Sellersdata).on("child_removed", (snaapshot) => {
            // console.log("s_cr")
            if (snaapshot.exists()) {
                removeStall(snaapshot.val())
            }
        });
    }, [])

    const removeStall = (_sellerData) => {
        // sellerData
        if (_data.current.sellerData.hasOwnProperty(_sellerData.comapnyId)) {
            delete _data.current.sellerData[_sellerData.comapnyId]
        }

        let _stallsData = _sellerData.stallData
        Object.keys(_stallsData).forEach((stallId) => {
            // stallData
            if (_data.current.stallData.hasOwnProperty(stallId)) {
                _data.current.stallData[stallId] = {
                    pos: _data.current.stallData[stallId].pos
                }
            }
            let _stall = document.querySelector(`#${stallId}`);
            // let fromHall5 = stallId.includes('Stall8');
            let fromHall5 = _stall.nodeName == 'g' //id.includes('Stall7');

            if (fromHall5) {
                if (_stall.children.length > 0) {
                    let path = null;
                    if (_stall.children[0].nodeName == 'path') {
                        path = _stall.children[0];
                    } else {
                        path = _stall.children[1];
                    }
                    if (path) {
                        path.style.fill = "white";
                        path.style.fillOpacity = "initial";
                        path.classList.remove("booked");
                    }
                }

            } else {
                _stall.style.fill = "white";
                _stall.style.fillOpacity = "initial";
                _stall.classList.remove("booked");
            }

        })

        updateSellersData();

    }

    const removeSellerDataforSVGWithGTag = (stallId) => {
        let _stall = document.querySelector(`#${stallId}`);
        console.log(_stall)
        // let fromHall5 = stallId.includes('Stall8');
        let fromHall5 = _stall.nodeName == 'g' //id.includes('Stall7');

        if (fromHall5) {
            if (_stall.children.length > 0) {
                let path = null;
                if (_stall.children[0].nodeName == 'rect') {
                    path = _stall.children[0];
                } else {
                    path = _stall.children[1];
                }
                if (path) {
                    let path = _stall.children[1];
                    path.style.fill = "none";
                    path.classList.remove("booked");
                }
            }
        } else {
            _stall.style.fill = "none";
            _stall.classList.remove("booked");
        }
    }

    const removeSellerDataforSVGForJIO = (stallId) => {
        let _stall = document.querySelector(`#Stall${stallId}`);
        console.log(_stall)
        // let fromHall5 = stallId.includes('Stall8');
        let fromHall5 = _stall.nodeName == 'g' //id.includes('Stall7');

        if (fromHall5) {
            if (_stall.children.length > 0) {
                let path = null;
                if (_stall.children[0].nodeName == 'rect') {
                    path = _stall.children[0];
                } else {
                    path = _stall.children[1];
                }
                if (path) {
                    let path = _stall.children[1];
                    path.style.fill = "none";
                    path.classList.remove("booked");
                }
            }
        } else {
            _stall.style.fill = "none";
            _stall.classList.remove("booked");
        }
    }

    const processSellerData = (_sellerData) => {
        if (_data.current.sellerData.hasOwnProperty(_sellerData.comapnyId)) {
            let _oldSellerData = _data.current.sellerData[_sellerData.comapnyId]
            let _stallsData = _oldSellerData.stallData
            Object.keys(_stallsData).forEach((stallId) => {
                console.log(stallId, "stallId")
                console.log(_stallsData)
                // removeSellerDataforSVGWithGTag(stallId);
                removeSellerDataforSVGForJIO(stallId);
            })
        }

        if (_sellerData.stallData) {
            let _stallsData = _sellerData.stallData
            // console.log(_stallsData, "_stallsData")
            Object.keys(_stallsData).forEach((stallId) => {
                _data.current.stallData[stallId] = {
                    ..._sellerData,
                    currentStallData: _stallsData[stallId]
                }
                // processStallId(stallId, _stallsData[stallId]);
                processStallIdWithPolylineTag(stallId, _stallsData[stallId]);
            })
        }
        _data.current.sellerData[_sellerData.comapnyId] = _sellerData;
        updateSellersData();
    }
    const calculateRect = (id) => {
        if (!_data.current.stallData[id]) {
            _data.current.stallData[id] = {}
        }

        let _stall = document.querySelector(`#${id}`);
        let rect = _stall.getBoundingClientRect();
        let svg_rect = svgContainerRef.current.getBoundingClientRect();
        let extraAddition = 0
        let extraAdditionR = 0
        if (svg_rect.top > rect.top) {
            extraAddition = svg_rect.top - rect.top
        }
        _data.current.stallData[id].posLeft = false
        if (rect.left + 300 > svg_rect.right) {
            _data.current.stallData[id].posLeft = true
        }
        let pos = {
            x: rect.left - extraAdditionR,
            y: rect.top + extraAddition,
        }
        createDiv(id, rect)

        _data.current.stallData[id].pos = pos
    }

    const processStallId_raw = (hd) => {
        // console.log(hd)
        let id = hd.stallId
        let area = hd.area;
        let _stall = document.querySelector(`#${id}`)
        if (!_stall) {
            return;
        }
        _stall.classList.add(`area_${hd.area}`);
        if (hd.section)
            _stall.classList.add(`${hd.section}`);
        else {
            console.error(hd)
        }
        calculateRect(id);
        const showCard = () => {
            if (areaFilterRef.current && area != areaFilterRef.current) {
                return
            }
            calculateRect(id);
            setStallId(id);
        }

        const hideCard = () => {
            // console.log("hideCard")
            setStallId(null);
        }

        _stall.addEventListener("click", (event) => {
            // console.log("click");
            if (isMobile) {
                showCard();
            }
        });

        _stall.addEventListener("mouseover", () => {
            // console.log("mouseover");
            if (!isMobile) {
                showCard();
            }
        });
        _stall.addEventListener("mouseout", () => {
            // console.log("onmouseout");
            if (!isMobile) {
                hideCard();
            }
        });
    }

    const processStallId = (id, stallInfo) => {
        if (!id) {
            return
        }
        let _stall = document.querySelector(`#${id}`);

        if (!_stall) {
            return
        }
        let fromHall5 = _stall.nodeName == 'g' //id.includes('Stall7');

        if (fromHall5) {
            if (_stall.children.length > 0) {
                let path = null;
                if (_stall.children[0].nodeName == 'path') {
                    path = _stall.children[0];
                } else {
                    path = _stall.children[1];
                }
                if (path) {
                    let stokeColor = path.getAttribute("stroke");
                    path.style.fill = stokeColor ? stokeColor : "orange";
                    path.style.fillOpacity = 0.7;
                    _stall.classList.add("booked");
                }
            }
        } else {
            let stokeColor = _stall.getAttribute("stroke");
            _stall.style.fill = stokeColor ? stokeColor : "orange";
            _stall.style.fillOpacity = 0.7;
            _stall.classList.add("booked");
        }

        // _stall.classList.add("area_" + stallInfo.area);
        _stall.classList.add("hallNo_" + stallInfo.hallNo);
        calculateRect(id);
    }

    const processStallIdWithPolylineTag = (id, stallInfo) => {
        if (!id) {
            return
        }
        let _stall = document.querySelector(`#${id}`);

        if (!_stall) {
            return
        }
        let fromHall5 = _stall.nodeName == 'g' //id.includes('Stall7');

        let needtoconsole = id == "StallJio-G_12e";
        if (fromHall5) {
            if (_stall.children.length > 0) {
                let path = null;
                let stokeColor = null;
                if (_stall.querySelector("polyline")) {
                    path = _stall.querySelector("polyline");
                    stokeColor = SectionDataColor[stallInfo.section]
                    window.testPol = path;
                } else if (_stall.querySelector("path")) {
                    path = _stall.querySelector("path");
                    stokeColor = SectionDataColor[stallInfo.section]
                } else if (_stall.querySelector("rect")) {
                    path = _stall.querySelector("rect");
                    stokeColor = SectionDataColor[stallInfo.section]
                }
                if (needtoconsole) {
                    console.log("path", path)
                    console.log(stallInfo)
                }

                if (path) {
                    path.style.fill = stokeColor ? stokeColor : "orange";
                    path.style.fillOpacity = 0.5;
                    _stall.classList.add("booked");
                }
            }
        } else {
            let stokeColor = _stall.getAttribute("stroke");
            _stall.style.fill = stokeColor ? stokeColor : "orange";
            _stall.style.fillOpacity = 0.7;
            _stall.classList.add("booked");
        }

        // _stall.classList.add("area_" + stallInfo.area);
        _stall.classList.add("hallNo_" + stallInfo.hallNo);
        calculateRect(id);
    }

    return (
        <>
            {
                !isMobile && stallId && _data.current.stallData[stallId].comapnyId &&
                <StallPopUp stallData={_data.current.stallData[stallId]} />
            }
            {
                areaFilter && stallId && !isMobile && !_data.current.stallData[stallId].comapnyId &&
                <StallPopUpNoInfo stallData={_data.current.stallData[stallId]} stallId={stallId} />
            }
            {
                isMobile && stallId && _data.current.stallData[stallId].comapnyId &&
                <>
                    <MboileStallPopUp
                        handleCloseButton={() => {
                            // console.log("MboileStallPopUp")
                            setStallId(null);
                        }}
                        stallData={_data.current.stallData[stallId]}
                    />
                </>
            }
            {
                areaFilter && isMobile && stallId && _data.current.stallData[stallId] &&
                <>
                    <MboileStallPopUp_NoInfo
                        handleCloseButton={() => {
                            // console.log("MboileStallPopUp")
                            setStallId(null);
                        }}
                        stallData={_data.current.stallData[stallId]}
                        stallId={stallId}
                    />
                </>
            }
            <div className={style.svgContainer} >
                <div className={`${style.svgContainerBody} ${areaFilter ? "areafilter_" + areaFilter : ""} ${sectionFilter ? sectionFilter + "_Filter" : ""}`} ref={svgContainerRef}>

                    <CustomTransformWrapper>
                        {
                            ({ zoomToElement }) => {
                                zoomToElementRef.current = zoomToElement;
                                return (
                                    <>

                                        <div className={`${style.placeholders} ph_div`}>
                                        </div>
                                        {/* <div className={style.testDivParent}>
                                            <div className={style.testDiv} style={{ background: "red" }}></div>
                                            <div className={style.testDiv} style={{ background: "blue" }}></div>
                                            <div className={style.testDiv} style={{ background: "yellow" }}></div>
                                            <div className={style.testDiv} style={{ background: "red" }}></div>
                                        </div> */}
                                        <FloorMap id="floorMap" />
                                    </>
                                )
                            }
                        }
                    </CustomTransformWrapper>
                </div>
            </div>
        </>
    )
}



// realDB.ref(REALDB_CONSTANT.Sellersdata).once("value", (snapshot) => {
//   if (snapshot.exists()) {
//     let _sellersData = snapshot.val()
//     let _stallData = {}
//     Object.keys(_sellersData).forEach(key => {
//       let _sellerData = _sellersData[key];
//       //
//       // if (_sellerData.stallId) {
//       //   _stallData[_sellerData.stallId] = {
//       //     ..._sellerData,
//       //   }
//       // }
//       //
//       if (_sellerData.stallData) {
//         let _stallsData = _sellerData.stallData
//         Object.keys(_stallsData).forEach((stallId) => {
//           _stallData[stallId] = {
//             ..._sellerData,
//           }
//         })
//       }
//     })
//     _data.current.stallData = {
//       ..._stallData
//     }
//     processData();
//   }
// });

// const processData = () => {
//     if (_data.current.stallData) {
//         Object.keys(_data.current.stallData).forEach(stallId => processStallId(stallId))
//     }
// }