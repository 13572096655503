import React from 'react'
import FloorPlanContainer from '../Component/FloorPlanContainer'
import MainLayout from '../Component/MainLayout'
import SearchContextProvider from '../Context/SearchContextProvider'

export default function Client() {
    return (
        <MainLayout needSearch>
            <FloorPlanContainer />
        </MainLayout>
        // </SearchContextProvider>
    )
}
