import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { auth } from '../firebase';

export const UserContext = createContext(null);

export default function UserContextProvider({ children }) {
    const [user, setUser] = useState(null)

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                localStorage.setItem("userAuth", JSON.stringify(user));
                setUser(user);
            } else {
                localStorage.removeItem("userAuth");
                sessionStorage.clear();
                localStorage.clear();
                setUser(null);
            }
        });
    }, []);

    return (
        <>
            <UserContext.Provider value={{ user }}>
                {children}
            </UserContext.Provider>
        </>
    )
}
