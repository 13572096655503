export const SectionData = {
  sectionData_1: "Diamond, Gemstone & Other Studded Jewellery",
  sectionData_2: "Gold & Gold CZ Studded Jewellery",
  sectionData_3: "Loose stones (Diamonds)",
  sectionData_4: "Silver Jewellery,Artifacts & Gifting Items",
  sectionData_5: "Laboratory & Education",
  sectionData_6: "Lab Grown Diamonds (Loose)",
  sectionData_7: "Lab Grown Diamonds (Jewellery)",
  // sectionData_8: "International Jewellery",
  sectionData_9: "Loose gem stone",
  // sectionData_10: "The Select Club",
  // sectionData_11: "Sponsar stall",
  // sectionData_12: "Machinery",
  sectionData_13: "Loose Stone ( Colour Stone )",
  sectionData_14: "IGJME Tritiya Bengaluru",
  
  // sectionData_11: "International loose",
  // "sectionData_6": "Finding",
  // "sectionData_8": "International Jewellery",
  // "sectionData_9": "Allied",
  // "sectionData_11": "International Machinery",
  // "sectionData_8": "International loose",
};

export const SectionDataColor = {
  sectionData_1: "#006bd6",
  sectionData_2: "#ff7d05",
  sectionData_3: "#ff0afe",
  sectionData_4: "#8ef485",
  sectionData_5: "#fdc689",
  sectionData_6: "#707070",
  sectionData_7: "#58ba48",
  sectionData_8: "#fed7e0",
  sectionData_9: "#ff0afe",
  sectionData_10: "#000",
  sectionData_11: "#000",
  sectionData_12: "#c60000",
  sectionData_13: "#006bd6",
  sectionData_14: "#c60000",
};
