import React, { useContext } from 'react'
import Login from '../Component/Login'
import MainLayout from '../Component/MainLayout'
import SellerForm from '../Component/SellerForm'
import { UserContext } from '../Context/UserContextProvider'
import style from './index.module.css'
export default function Admin() {
  const { user } = useContext(UserContext)
  return (
    <>
      <MainLayout needBackground>
        {/* <h2 className={style.venuTxt} >NESCO VENUE</h2> */}
        {
          user ?
            <SellerForm />
            :
            <Login />
        }
      </MainLayout>
    </>
  )
}
