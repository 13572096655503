import React, { useReducer, useState } from 'react'
import { CircularLoading } from '../../Constant/AppConstant';
import { auth } from '../../firebase';
import style from './index.module.css';

export default function Login() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await auth.signInWithEmailAndPassword(email, password);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setError(true);
        }
    }
    return (
        <>
            <div className={style.formPageContainer}>
                <div className={style.formBody}>
                    <h3>
                        Login Seller Dashboard
                    </h3>
                    <form onSubmit={handleSubmit} autoComplete={false}>
                        {/* Seller */}
                        <div className={style.formInputContainer}>
                            <input type={"text"} value={email} placeholder="Enter Email" onChange={(e) => {
                                setEmail(e.target.value);
                            }} />
                        </div>

                        {/* Passowrd */}
                        <div className={style.formInputContainer}>
                            <input type={"password"} value={password} placeholder="Enter Password" onChange={(e) => {
                                setPassword(e.target.value);
                            }} />
                        </div>
                        {
                            error &&
                            <div className={style.error}>
                                *Please use a valid combination of email and password
                            </div>
                        }

                        <div className={style.buttonContainer}>
                            <button className={style.submitButton} type='submit' disabled={false}>
                                {
                                    loading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={loading ? { visibility: "hidden" } : {}}>
                                    Save
                                </span>
                            </button>

                            {/* <button className={style.submitButton} onClick={() => {
                                window.location.href = 'https://iijs-jio.web.app/admin'
                            }} disabled={false}>
                                {
                                    loading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={loading ? { visibility: "hidden" } : {}}>
                                    GOTO: JWCC Venue
                                </span>
                            </button> */}
                            {/* <button className={style.submitButton} onClick={() => {
                                window.location.href = 'https://iijs-igjme.web.app/admin'
                            }} disabled={false}>
                                {
                                    loading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={loading ? { visibility: "hidden" } : {}}>
                                    GOTO: IGJME Venue
                                </span>
                            </button> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
