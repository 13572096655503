export const AreaOptionData = {
    "9": "9",
    "12": "12",
    "16": "16",
    "18": "18",
    "27": "27",
    "36": "36",
    "54": "54",
    "72": "72",
    "108": "108",
}

export const HALL_KEYS_CONSTANT_DATA = {
    // "JWCC": "JWCC",
    // "h1": "h1",
    // "h2": "h2",
    // "h3": "h3",
    "h4": "h4",
    "h5": "h5",
    // "h6": "h6",
    // "h7": "h7",
}
export const HALL_CONSTANT_DATA = {
    // [HALL_KEYS_CONSTANT_DATA.JWCC]: "JWCC",
    // [HALL_KEYS_CONSTANT_DATA.h1]: "Hall 1",
    // [HALL_KEYS_CONSTANT_DATA.h2]: "Hall 2",
    // [HALL_KEYS_CONSTANT_DATA.h3]: "Hall 3",
    [HALL_KEYS_CONSTANT_DATA.h4]: "Hall 4",
    [HALL_KEYS_CONSTANT_DATA.h5]: "Hall 5",
    // [HALL_KEYS_CONSTANT_DATA.h6]: "Hall 6",
    // [HALL_KEYS_CONSTANT_DATA.h7]: "Hall 7",
}
export const AREA_HALL_MAPPING = {
    // "9": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4], //HALL_KEYS_CONSTANT_DATA.h6, HALL_KEYS_CONSTANT_DATA.h7,
    // "12": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4],//HALL_KEYS_CONSTANT_DATA.h6
    // "16": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4],
    // "18": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4],//HALL_KEYS_CONSTANT_DATA.h7,
    // "27": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3,],//HALL_KEYS_CONSTANT_DATA.h7,
    // "36": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, , HALL_KEYS_CONSTANT_DATA.h3, , HALL_KEYS_CONSTANT_DATA.h4],//HALL_KEYS_CONSTANT_DATA.h7
    // "54": [HALL_KEYS_CONSTANT_DATA.h1, , HALL_KEYS_CONSTANT_DATA.h2, , HALL_KEYS_CONSTANT_DATA.h3, , HALL_KEYS_CONSTANT_DATA.h4],//HALL_KEYS_CONSTANT_DATA.h7
    // "72": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h2, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4],
    // "108": [HALL_KEYS_CONSTANT_DATA.h1, HALL_KEYS_CONSTANT_DATA.h3, HALL_KEYS_CONSTANT_DATA.h4],
    "9": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5], 
    "12": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "16": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "18": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "27": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "36": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "54": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "72": [HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
    "108":[HALL_KEYS_CONSTANT_DATA.h4, HALL_KEYS_CONSTANT_DATA.h5],
}
