import React, { useEffect, useState } from 'react'
import { HALL_CONSTANT_DATA } from '../../Constant/AreaOptionData';
import { SectionData } from '../../Constant/SectionData';
import style from './index.module.css';
import { FiChevronDown } from 'react-icons/fi';
import { StallIdToStallName } from '../../util';



export default function StallPopUp({ stallData }) {
    return (
        <div className={`${style.stallInfoContainer} ${stallData.posLeft ? style.posLeft : ""}`}
            style={stallData.pos ? {
                top: stallData.pos.y,
                left: stallData.pos.x
            } : {}}
        >
            {
                stallData.comapnyId &&
                <div className={style.NameContainer}>
                    <span>
                        {
                            stallData.company_name
                        }
                    </span>
                    <span>
                        {
                            stallData.currentStallData &&
                            HALL_CONSTANT_DATA[stallData.currentStallData.hallNo]
                        }
                    </span>
                </div>
            }
            <div className={style.stallNoContainer}>
                Stall No. : &nbsp;
                {StallIdToStallName(stallData.currentStallData.stallId)}
            </div>
            {
                stallData.comapnyId &&
                <div className={style.sectionContainer}>
                    Section : &nbsp;
                    {SectionData[stallData.section]}
                </div>
            }
        </div>
    )
}

export function StallPopUpNoInfo({ stallData, stallId }) {
    return (
        <div className={`${style.stallInfoContainer} ${style.stallInfoContainer_areaFilter}`}
            style={stallData.pos ? {
                top: stallData.pos.y,
                left: stallData.pos.x
            } : {}}
        >
            {/* {
                stallData.comapnyId &&
                <div className={style.NameContainer}>
                    <span>
                        {
                            stallData.company_name
                        }
                    </span>
                    <span>
                        {
                            stallData.currentStallData &&
                            HALL_CONSTANT_DATA[stallData.currentStallData.hallNo]
                        }
                    </span>
                </div>
            } */}
            <div className={style.stallNoContainer}>
                <b>Stall No.</b> : &nbsp;
                {StallIdToStallName(stallId)}
            </div>
            {/* {
                stallData.comapnyId &&
                <div className={style.sectionContainer}>
                    Section : &nbsp;
                    {SectionData[stallData.section]}
                </div>
            } */}
        </div>
    )
}


export function MboileStallPopUp({ stallData = {
    "comapnyId": "1",
    "company_name": "PARAS PEARLS CO LTD",
    "region": "region_2",
    "section": "sectionData_5",
    "stallData": { "Area5": { "area": "12", "hallNo": "h6", "stallId": "Area5" } },
    "currentStallData": { "area": "12", "hallNo": "h6", "stallId": "Area5" },
    "pos": { "x": 192.13499450683594, "y": 198.5928955078125 }
}, handleCloseButton = () => { } }) {
    const [close, setclose] = useState(false);
    // useEffect(() => {
    //     if(close){
    //     }
    // }, [close])

    return (
        <div className={`${style.mStallInfoContainer} ${close ? style.mStallInfoContainer_close : style.mStallInfoContainer_open}`} >
            <div className={style.closeButton} onClick={(e) => {
                e.preventDefault();
                if (close) { return }
                setclose(true);
                setTimeout(() => {
                    handleCloseButton();
                }, 600)
            }}>
                <FiChevronDown />
            </div>
            <div className={style.NameContainer}>
                <span>
                    {
                        stallData.company_name
                    }
                </span>
                <span>
                    {
                        stallData.currentStallData &&
                        HALL_CONSTANT_DATA[stallData.currentStallData.hallNo]
                    }
                </span>
            </div>
            <div className={style.stallNoContainer}>
                <b>
                    Stall No. : &nbsp;
                </b>
                {StallIdToStallName(stallData.currentStallData.stallId)}
            </div>
            <div className={style.sectionContainer}>
                <b>
                    Section : &nbsp;
                </b>
                {SectionData[stallData.section]}
            </div>
        </div>
    )
}


export function MboileStallPopUp_NoInfo({ stallData, stallId, handleCloseButton = () => { } }) {
    const [close, setclose] = useState(false);
    // useEffect(() => {
    //     if(close){
    //     }
    // }, [close])

    return (
        <div className={`${style.mStallInfoContainer} ${close ? style.mStallInfoContainer_close : style.mStallInfoContainer_open}`} >
            <div className={style.closeButton} onClick={(e) => {
                e.preventDefault();
                if (close) { return }
                setclose(true);
                setTimeout(() => {
                    handleCloseButton();
                }, 600)
            }}>
                <FiChevronDown />
            </div>
            {/* <div className={style.NameContainer}>
                <span>
                    {
                        stallData.company_name
                    }
                </span>
                <span>
                    {
                        stallData.currentStallData &&
                        HALL_CONSTANT_DATA[stallData.currentStallData.hallNo]
                    }
                </span>
            </div> */}
            <div className={style.stallNoContainer}>
                <b>
                    Stall No. : &nbsp;
                </b>
                {StallIdToStallName(stallId)}
            </div>
            {/* <div className={style.sectionContainer}>
                <b>
                    Section : &nbsp;
                </b>
                {SectionData[stallData.section]}
            </div> */}
        </div>
    )
}


