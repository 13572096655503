import React, { useContext, useState, useEffect } from 'react'
import { LogoImage, SearchImage } from '../../Constant/AppConstant'
import { HALL_CONSTANT_DATA } from '../../Constant/AreaOptionData';
import { SectionData } from '../../Constant/SectionData';
import { SearchContext } from '../../Context/SearchContextProvider'
import style from './index.module.css'
import { MyDropDown } from "../MyDropDown"
import { isMobile } from 'react-device-detect';

export default function MainLayout(props) {
    const { currentSearchList, updateSellersData, search, clearSearch, zoomToStall, _data } = useContext(SearchContext);
    const [searchTxt, setSearchTxt] = useState("");
    const { setAreaFilterId, setSectionFilterId } = useContext(SearchContext);

    useEffect(() => {
        if (searchTxt.length > 0) {
            search(searchTxt)
        } else {
            clearSearch();
        }
    }, [searchTxt])

    return (
        <div className={`${style.mainContainer} ${props.needBackground ? style.mainContainer_bg : ""}`}>

            <div className={style.header} style={!props.needSearch ? {
                justifyContent: 'flex-end'
            } : {}}>
                {
                    props.needSearch &&
                    <div className={style.leftHeaderContianre}>
                        <div className={style.searchBoxConatainer} >
                            <img className={style.searchBoxIcon} src={SearchImage} alt="searchIcon" />
                            <input
                                className={style.searchBox}
                                type="text"
                                placeholder="Search for stalls"
                                onChange={(event) => {
                                    if (Object.keys(_data.current.sellerData).length == 0) {
                                        return
                                    }
                                    // console.log(event.target.value)
                                    setSearchTxt(event.target.value)
                                }}
                                value={searchTxt}
                            />
                            {
                                currentSearchList &&
                                <div className={style.search_result_conatiner}>
                                    {
                                        currentSearchList.map((_stallData, index) => {
                                            let locData = _stallData.stallData[Object.keys(_stallData.stallData)[0]]
                                            return (
                                                <div key={index} className={style.search_result}>
                                                    <div className={style.NameContainer}>
                                                        <span>
                                                            {
                                                                _stallData.company_name
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                locData &&
                                                                HALL_CONSTANT_DATA[locData.hallNo]
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className={style.stallNoContainer}>
                                                        Stall No.: {locData.stallId}
                                                    </div>
                                                    <div className={style.sectionContainer}>
                                                        Section: {SectionData[_stallData.section]}
                                                    </div>
                                                    <div>
                                                        <button className={style.stallBtn} onClick={(e) => {
                                                            e.preventDefault();
                                                            zoomToStall(locData.stallId);
                                                            setSearchTxt("")
                                                        }}>
                                                            View More &gt;
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <MyDropDown
                            // className={style.MyDropDown}
                            onChnage={(option) => {
                                if (option) {
                                    setAreaFilterId(option.value);
                                }
                            }}
                        >
                            {
                                (selectedOption) => !selectedOption.value ?
                                    "Area "
                                    :
                                    `${isMobile ? "A" : "Area"}: ${selectedOption.label}`
                            }
                        </MyDropDown>
                        <MyDropDown
                            className={style.seachDropDown}
                            autoWidth
                            options={
                                [
                                    { value: null, label: "All" },
                                    { value: "sectionData_1", label: "Diamond, Gemstone & Other Studded Jewellery" },
                                    { value: "sectionData_2", label: "Gold & Gold CZ Studded Jewellery" },
                                    { value: "sectionData_3", label: "Loose Stones  (Diamonds)" },
                                    { value: "sectionData_4", label: "Silver Jewellery, Artifacts & Gifting Items" },
                                    { value: "sectionData_5", label: "Laboratories & Education" },
                                    { value: "sectionData_6", label: "Lab Grown Diamonds (Loose)" },
                                    { value: "sectionData_7", label: "Lab Grown Diamonds (Jewellery)" },
                                    // { value: "sectionData_8", label: "International Jewellery" },
                                    // { value: "sectionData_9", label: "Loose gem stone" },
                                    // { value: "sectionData_12", label: "Machinery" },
                                    { value: "sectionData_13", label: "Loose Stone ( Colour Stone )" },
                                    { value: "sectionData_14", label: "IGJME Tritiya Bengaluru" },
                                    // { value: "sectionData_5", label: "International" },
                                    // { value: "sectionData_6", label: "Finding" },
                                    // { value: "sectionData_8", label: "International Jewellery" },
                                    // { value: "sectionData_9", label: "Allied" },
                                    // { value: "sectionData_11", label: "International Machinery" },
                                ]
                            }
                            onChnage={(option) => {
                                if (option) {
                                    setSectionFilterId(option.value);
                                }
                            }}
                        >
                            {
                                (selectedOption) => !selectedOption.value ?
                                    "Section"
                                    :
                                    `${isMobile ? "S" : "Section"}: ${selectedOption.label}`
                            }
                        </MyDropDown>

                        {/* <button className={style.VenuBtn} onClick={() => {
                            window.location.href = 'https://iijs-jio.web.app/'
                        }}>
                            GOTO: JWCC Venue
                        </button>
                        <button className={style.VenuBtn} onClick={() => {
                            window.location.href = 'https://iijs-igjme.web.app/'
                        }}>
                            GOTO: IGJME Venue
                        </button> */}

                    </div>

                }
                <img src={LogoImage} alt={"logoImage"} />
            </div>

            <div className={style.childrenContainer}>
                {props.children}
            </div>
        </div>
    )
}
