import React, { useContext, useEffect, useRef, useState } from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { CompassIcon, LegendsIcon, VenuImage, ZoomInImage, ZoomOutImage } from '../../Constant/AppConstant';
import style from "./index.module.css";
import { SearchContext } from '../../Context/SearchContextProvider';
import { MyDropDown } from "../MyDropDown"
import { isMobile } from 'react-device-detect';
import { GrFormClose } from "react-icons/gr";


const MAX_SCALE = 8;
// const Legends = [
//     { label: "GOLD", color: "#FF7D05" },
//     { label: "SILVER", color: "#7F7F7F" },
//     { label: "GEM STONE", color: "#FF0AFE" },
//     { label: "MACHINARY", color: "#CAC981" },
//     { label: "INTERNATIONAL MACHINARY", color: "#00BCBC" },
//     { label: "DIAMOND", color: "#006BD6" },
//     { label: "FINDING", color: "#000000" },
//     { label: "LAB", color: "#008400" },
//     { label: "ALLIED", color: "#C60000" }
// ]

const Legends = [
    { label: "Diamond, Gemstone & Other Studded Jewellery", color: "#1174d8" , backgroundColor: "#d9f0f8" },
    { label: "Gold & Gold CZ Studded Jewellery", color: "#ff7d05" , backgroundColor: "#ecebca" },
    { label: "Loose Stones (Diamonds)", color: "#ff0afe" , backgroundColor: "#d2a3cb" },
    { label: "Silver Jewellery, Artifacts & Gifting Items", color: "#7f7f7f", backgroundColor: "#8ef485" },
    { label: "Laboratories & Education", color: "#008400" , backgroundColor: "#ffc58e" },
    { label: "Lab Grown Diamonds (Loose)", color: "#707070", backgroundColor: "#afaeaf" },
    { label: "International Jewellery", color: "#000" , backgroundColor: "#fed7e0" },
    { label: "Lab Grown Diamonds (Jewellery)", color: "#58ba48", backgroundColor: "#7a8229" },
    { label: "Machinery", color: "#c60000", backgroundColor: "#ecebca" },
    { label: "Loose Stone ( Colour Stone )", color: "#1174d8" , backgroundColor: "#d9f0f8" },
    { label: "IGJME", color: "#ff7d05" , backgroundColor: "#ecebca" },


    // { label: "Finding", color: "#000000" , backgroundColor: "#ecebca" },
    // { label: "Allied", color: "#C60000" , backgroundColor: "#ecebca" },
    // { label: "International Machinery", color: "#CAC981" , backgroundColor: "#ecebca" },
]

export default function CustomTransformWrapper(props) {
    const { setAreaFilterId } = useContext(SearchContext);
    const wrapperContext = useRef(null)
    const [legendsOpen, setLegendsOpen] = useState(false);
    const [showLayout, setShowLayout] = useState(false);
    return (
        <>
            <TransformWrapper
                initialScale={1}
                limitToBounds={!isMobile}
                // centerZoomedOut={false}
                // centerOnInit={true}
                zoomAnimation={
                    {
                        animationTime: 200
                    }
                }
                pinch={isMobile ? {
                    step: 2
                } : {}}
                maxScale={MAX_SCALE}
                // onZoom={(a, b) => {
                //     console.log(a, b)
                // }}
                ref={wrapperContext}
            >
                {({ state, zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
                    <React.Fragment>
                        <div className={style.toolkit}>

                            <button onClick={() => zoomIn(0.25)}>
                                <img src={ZoomInImage} alt="zoomIn" />
                            </button>
                            <span>
                                {100 + (Math.floor((100 / (MAX_SCALE - 1)) * (state.scale - 1)))}%
                            </span>
                            <button onClick={() => zoomOut()}>
                                <img src={ZoomOutImage} alt="zoomOut" />
                            </button>

                            {/* <button onClick={() => resetTransform()}>x</button> */}
                        </div>
                        <div className={`${style.legendsContainer} `}>
                            <div className={`${style.legendsBox} ${legendsOpen ? style.legend_open : ""}`}>
                                <div className={`${style.dflex} ${style.justifyBetween}`}>
                                    <h3>
                                        LEGEND
                                    </h3>
                                    <img src={CompassIcon} alt={"compassIcon"} />
                                </div>
                                <div className={`${style.legendschildContianer} `} >
                                    {
                                        Legends.map(l => (
                                            <div key={l.label} className={style.legendSingle}>
                                                <span>
                                                    {
                                                        l.label
                                                    }
                                                </span>
                                                <span className={style.legendsBox_set} style={{
                                                    borderColor: l.color,
                                                    backgroundColor: l.backgroundColor
                                                }}>

                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={style.legendsIcon} onClick={() => {
                                setLegendsOpen(prev => !prev)
                            }}>
                                <img src={LegendsIcon} alt={"legends"} />
                            </div>

                        </div>

                        {/* <div className={style.layoutButton} onClick={(e) => {
                            e.preventDefault();
                            setShowLayout(true);
                        }}>
                            Venue Layout
                        </div> */}
                        {/* <MyDropDown onChnage={(option) => {
                        if (option) {
                            setAreaFilterId(option.value);
                        }
                    }} /> */}
                        <TransformComponent>
                            {
                                props.children({
                                    zoomToElement
                                })
                            }
                        </TransformComponent>
                    </React.Fragment>
                )
                }
            </TransformWrapper >
            {
                <div className={`${style.modalContainer} ${showLayout ? style.modalContainer_visible : ""}`}>
                    <div className={style.modalParent}>
                        <div className={style.closeBtn} onClick={() => {
                            setShowLayout(false);
                        }} >
                            <GrFormClose />
                        </div>
                        <img src={VenuImage} alt={"layout"} />
                    </div>
                </div>
            }
        </>
    )
}

