import './App.css';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Link
} from "react-router-dom";
import Client from './Page/Client';
import Admin from './Page/Admin';
import SearchContextProvider from './Context/SearchContextProvider';
import UserContextProvider from './Context/UserContextProvider';
import Test from './Page/Test';

function App() {
  return (
    <>
      <UserContextProvider>
        <SearchContextProvider>
          <Router>
            <Switch>
              {/* <Route path="/test" element={<Test />} /> */}
              <Route path="/admin" element={<Admin />} />
              <Route path="/" element={<Client />} />
            </Switch>
          </Router>
        </SearchContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
