export const StallIdToStallName = (id = "Stall4_533C") => {
    let _id = id;
    _id = _id.replace("_", ".");
    return _id.slice(5, _id.length)
}

export const AddClassNameToElement = (arr, cb = () => { }) => {
    arr.forEach(hd => {
        let el = document.querySelector(`#${hd.stallId}`)
        if (el) {
            el.classList.add(`area_${hd.area}`);
        }
        cb(el);
        // console.log(hd.stallId);
    })
}