// Area mapping
// No two can have same stallId
//

import { HALL_KEYS_CONSTANT_DATA } from "./AreaOptionData";

const Hall1_layoutData = [
  {
    stallId: "Stall1A_1",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_2",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_3",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_4",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_5",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_6",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_7",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_8",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_9",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_10",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_11",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_12",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_13",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_14",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_15",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_16",
    area: 16,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_17",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_18",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_19",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_20",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_21",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_22",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_23",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_28",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_29A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_29B",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_29C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_29D",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_30A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_30B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_31A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_31B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_31C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_31D",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_32A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_32B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_32C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_32D",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_32E",
    area: 54,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_33A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_33B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_34A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_34B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_35A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_35B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_35C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_35D",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_36A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_36B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_37A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_37B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_38A",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_38B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_38C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1A_38D",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  // {
  //   stallId: "Stall1A_38E",
  //   area: 18,
  //   hallId: 1,
  //   section: "sectionData_1",
  // },
  {
    stallId: "Stall1B_39",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_40",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_41",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_42",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_43",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_44",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_45",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_46",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_47",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_48",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_49",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_50A",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_50B",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_50C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_51A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_51B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_52A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_52B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_53",
    area: 72,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_54A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_54B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_55A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_55B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_56A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_56B",
    area: 54,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_57A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_57B",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_58A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_58B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_59",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_60",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_61",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_64A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_64B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_65A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_65B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_65C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_65D",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_65E",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_66A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_66B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_66C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_66D",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_66E",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_67A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_67B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_67C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_67D",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_67E",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_68A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_68B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1B_68C",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1C_69A",
    area: 36,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1B_69B",
    area: 18,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1B_69C",
    area: 9,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1B_69D",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_69E",
    area: 9,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1C_69H",
    area: 9,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1C_69F",
    area: 9,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1C_69G",
    area: 18,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1C_70A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_70B",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_70C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_70D",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_71A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1B_71B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_72A",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72C",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72D",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72E",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72F",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72G",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1B_72H",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72I",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72J",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72K",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72L",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72M",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_72N",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_73E",
    area: 36,
    hallId: 1,
    section: "sectionData_5",
  },
  {
    stallId: "Stall1D_73A",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_73B",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_73C",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_73D",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_75A",
    area: 54,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_75B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_74A",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1C_74B",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_76A",
    area: 54,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_76B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_74C",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_74D",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_77A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_77B",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_77C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_77D",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_77E",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_78A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_78B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_78C",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_79A",
    area: 108,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_79B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_79C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_80A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_80B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_80C",
    area: 108,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_81A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_81B",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_81C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_81D",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_81E",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_81F",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_82A",
    area: 36,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_82B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_82C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_83A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_83B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_83C",
    area: 108,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_84A",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_84B",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_84C",
    area: 9,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_84D",
    area: 27,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_85A",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_85B",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_85C",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_85D",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1C_87",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_88",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_89",
    area: 18,
    hallId: 1,
    section: "sectionData_1",
  },
  {
    stallId: "Stall1D_90",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_91",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_92",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_93",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_94",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_95A",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_95B",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_95C",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_95D",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_95E",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_95F",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_96A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_96B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_96C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_97A",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_97B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_97C",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_97D",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_98A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_98B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_98C",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_99",
    area: 72,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_100A",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_100B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_100C",
    area: 72,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_101A",
    area: 72,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_101B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_101C",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_102A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1D_102B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_102C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_103A",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_103B",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_103C",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_103D",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_103E",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_104A",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_104B",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_104C",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_104D",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_105",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_106A",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_106B",
    area: 27,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_106C",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_106D",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_106E",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_106F",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_107A",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_107B",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_107C",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_107D",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1D_107E",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_108A",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_108B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_108C",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_108D",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_109A",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_109B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_109C",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_109D",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_110A",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_110B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_110C",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1E_110D",
    area: 27,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_110E",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_110F",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_110G",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_110H",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_111A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_111B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_112",
    area: 72,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_113A",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_113B",
    area: 72,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_114A",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_114B",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_114C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_114D",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_114E",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_115A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_115B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_115C",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_116A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_116B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_117A",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_117B",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_117C",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_117D",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_118",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1E_119",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_120",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_121",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_122",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_123A",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_123B",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_123C",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_123D",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_124A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_124B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_125A",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_125B",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_126A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_126B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_127A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_127B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_128",
    area: 108,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_129A",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_129B",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_130A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_130B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_131A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_131B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_132A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_132B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_132C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_133A",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_133B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_133C",
    area: 27,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_134A",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_134B",
    area: 9,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_134C",
    area: 27,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_135A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_135B",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_135C",
    area: 18,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_135D",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1F_136",
    area: 16,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_137",
    area: 16,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_138",
    area: 12,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_139",
    area: 16,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_140",
    area: 16,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1F_141",
    area: 16,
    hallId: 1,
    section: "sectionData_9",
  },
  {
    stallId: "Stall1G_142A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_142B",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_142C",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_142D",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_143A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_143B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_143C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_144A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_144B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_144C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_145A",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_145B",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_145C",
    area: 18,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_146A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_146B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_147A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_147B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_148A",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_148B",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_149",
    area: 108,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_150A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_150B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_151A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_151B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_152",
    area: 54,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153A",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153B",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153C",
    area: 36,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153D",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153E",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_153F",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_154A",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_154B",
    area: 27,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_155A",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_155B",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_155D",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_155C",
    area: 12,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_156",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_157",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_158",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_159",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_160",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_161",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_162",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_163",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_164",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_165",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_166",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_167",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_168",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_169",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_170",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_171",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_172",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_173",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_174",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_175",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_176",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_177",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_178",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_179",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_180",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_181",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_182",
    area: 16,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_183",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_184",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_185",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_186",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_187",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_188",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_189",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_190",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_191",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_192",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_193A",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_193B",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
  {
    stallId: "Stall1G_193C",
    area: 9,
    hallId: 1,
    section: "sectionData_2",
  },
];

const Hall2_layoutData = [
  {
    stallId: "Stall2H_193",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_194",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_195",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_196",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_197",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_198",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_199",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_200",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_201",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_202",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_203",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_204",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_205",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_206",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_207",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_208",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_209",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_210",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_211",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_212",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_213",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_214",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_215",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_216",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_217",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_218",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_219",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_220",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_221",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_222",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_223",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_224",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_225",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_226",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_227",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_228",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_229",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_230",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_231",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_232",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_233",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_234",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_235",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_236",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_237",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_238",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_239",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_240",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_241",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_242",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_243",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_244",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_245A",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_245B",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_245C",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_245D",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_245E",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_245F",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_245G",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_245H",
    area: 12,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_246A",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_246B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_246C",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_247A",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_247B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_247C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_247D",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_248A",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_248B",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_248C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_248D",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_249A",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_249B",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_249C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_249D",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_250A",
    area: 36,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_250B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_250C",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_251A",
    area: 36,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_251B",
    area: 36,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_252A",
    area: 36,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_252B",
    area: 36,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_253A",
    area: 54,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_253B",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_253C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_254A",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_254B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_254C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_254D",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_254E",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_255A",
    area: 54,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_255B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_256A",
    area: 54,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_256B",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_256C",
    area: 9,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_257A",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_257B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_257C",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_257D",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_258A",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_258B",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_258C",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_259A",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2H_259B",
    area: 18,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_259C",
    area: 27,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2K_260A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_260B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_260C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_260D",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_260E",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_261A",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_261B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_261C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_262A",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_262B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_262C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_263A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_263B",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_263C",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_263D",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_264A",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_264B",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_265A",
    area: 54,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_265B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_266",
    area: 72,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_267",
    area: 72,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_268A",
    area: 54,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_268B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_269A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_269B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_269C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_269D",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_269E",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_270A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_270B",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_270C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_271A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_271B",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_271C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_272A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_272B",
    area: 36,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_272C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_273A",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_273B",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_273C",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_273D",
    area: 18,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_274A",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_274B",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_274C",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_274D",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_274E",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_274F",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2J_275",
    area: 16,
    hallId: 2,
    section: "sectionData_1",
  },
  {
    stallId: "Stall2J_276",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_277",
    area: 16,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_278",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_279",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_280",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_281",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_282",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_283",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_284",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_285",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_286",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_287",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_288",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_289",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_290",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_291",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_292",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_293",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_294",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_295",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_296",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_297",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_298",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_299",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_300",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_301",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_302",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_303",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_304",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_305",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_306",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_307",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_308",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_309",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_310",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_311",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_312",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_313",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_314",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_315",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_316",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_317",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_318",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_319",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_320",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_321",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
  {
    stallId: "Stall2K_322",
    area: 9,
    hallId: 2,
    section: "sectionData_2",
  },
];

const Hall3_layoutData = [
  {
    stallId: "Stall3L_323",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_324",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_325",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_326",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_327",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_328",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_329",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_330",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_331",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_332",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_333",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_334",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_335",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_336",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_337",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_338",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_339",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_340",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_341",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_342",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_343",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_344",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_345",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_346",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_347",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_348",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_349",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_350",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_351",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_352",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_353",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_354",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_355",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_356",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_357",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_358",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_359",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_360",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_361",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_362",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_385",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_386",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_387",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_388",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_389",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_416",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_417",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_418",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_419",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_420",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_421",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_422",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_423",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_424",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_425",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_426",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_427",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_428",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_429",
    area: 12,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_430",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_431A",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_431B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_432",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_433",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_434",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_435",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_436",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_437",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_438",
    area: 16,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_439",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_440",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_441",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_442",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_443",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_444",
    area: 12,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_445",
    area: 12,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_446",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_447",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_448",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_449",
    area: 16,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_450",
    area: 12,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_401A",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_401B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_401C",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_401D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_402A",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_402B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_402C",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_402D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_402E",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_402F",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_402G",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_403A",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_403B",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_403C",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_404A",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_404B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_404C",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_404D",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_405A",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_405B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_405C",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_405D",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_406A",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_406B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_406C",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_406D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_406E",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_406F",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_407A",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_407B",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_407C",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_407D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_407E",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_407F",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3Q_408A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_408B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_408C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_408D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_395A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_395B",
    area: 72,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_395C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_394A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_394B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_394C",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_394D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_394E",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_409A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_409B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_409C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_409D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_410A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_410B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_410C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_410D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_411A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_411B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_411C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_411D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_412A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_412B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_412C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_412D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_413",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_414A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_414B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_414C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_414D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_415A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_415B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_415C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3Q_415D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_390A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_390B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_390C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_390D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_390E",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_391A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_391B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_391C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_391D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_391E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_392A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_392B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_392C",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_392D",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_392E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3P_393",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_396",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_397",
    area: 36,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_398A",
    area: 36,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_398B",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_398C",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_398D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_398E",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_398F",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_399A",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_399B",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_399C",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_399D",
    area: 18,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_400A",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_400B",
    area: 27,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3N_400C",
    area: 36,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_400D",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3P_400E",
    area: 9,
    hallId: 3,
    section: "sectionData_4",
  },
  {
    stallId: "Stall3M_363A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_363B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_363C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_363D",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_363E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_363F",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_364A",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_364B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_364C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_364D",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_365A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_365B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_365C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_365D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_365E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_366A",
    area: 54,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_366B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_366C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_367A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_367B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_367C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_367D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_367E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_368A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_368B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_368C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_368D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_368E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_369A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_369B",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_369C",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_370A",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_370B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_370C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_370D",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_371A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_371B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_371C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_371D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_371E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_372A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_372B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_372C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_372D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_372E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_373A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_373B",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_373C",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_374A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_374B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_374C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_374D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_374E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_375",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_376A",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_376B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_376C",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_376D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_377A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_377B",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3L_377C",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_377D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_378A",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_378B",
    area: 27,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_378C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_378D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_379A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_379B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_379C",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_379D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_380A",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_380B",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_380C",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_380D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_380E",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_380F",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_381",
    area: 108,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_382B",
    area: 108,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_382A",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_383A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_383B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_383C",
    area: 54,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_384A",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_384B",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_384C",
    area: 36,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3N_384D",
    area: 18,
    hallId: 3,
    section: "sectionData_2",
  },
  {
    stallId: "Stall3M_377E",
    area: 9,
    hallId: 3,
    section: "sectionData_2",
  },
];

const Hall4_layoutData = [
  {
    stallId: "Stall4A_1",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_2",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_3",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_4",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_5",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_6",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_7",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_8",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_9",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_10",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_11",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_12",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_13",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_14",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_15",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_16",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_17",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_18",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_19",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_20",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_21",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_22",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_23",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_24",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_25",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_26",
    area: 16,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_27",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_28",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_29",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_30",
    area: 12,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_36H",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_36F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_36F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_36E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_37A",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_37D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_37E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_37F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_37G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_37H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_38A",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_38B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_38C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_38D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_38E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_38F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_38G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_38H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_39A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_39B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_39C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_39D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_40A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_40B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_40C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_40D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_41A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_41B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_41C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_41D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_42A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_42B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_42C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_42D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_43A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_43B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_43C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_43D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_44A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_44B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_44C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_44D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_45A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_45B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_45C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_45D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_46A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_46B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_46C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_47A",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_47B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_47C",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_48A",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_48B",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_49A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_49B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_49B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_49C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_49D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_50A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_50B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_50C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_50D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_51A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_51B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_51C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_51D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_52A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_52B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_52C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_53A_",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_53A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_53B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_53C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_54A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_54B",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_54C",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_55A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_55B",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_55C",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_56A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_56B",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_56C",
    area: 27,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4A_57",
    area: 108,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_58A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_58B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_59A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_59B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_60A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_60B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_61",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_62",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_63A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_63B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_64A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_64B",
    area: 54,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_65A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_65B",
    area: 54,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_66A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_66B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_66C",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_66D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_67A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_67B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_67C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_68",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_69",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_70A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_70B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_70C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_71A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_71B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_71C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_72A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_72B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_72C",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_73A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_73B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_74A",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_74B",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_75",
    area: 36,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_76A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_76B",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_77A",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_77F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_77E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_77C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_77D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_77E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_77F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_77G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_77H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_78H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4B_78G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_78F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_78E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_83H",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_83G",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_83F",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_125J",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_83E",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_84A",
    area: 36,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_84B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_84C",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_84D",
    area: 18,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_85A",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_85B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_86",
    area: 36,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_87A",
    area: 36,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_87B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_87C",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_88A",
    area: 54,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_88B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4C_89A",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_89B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_89C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_89D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_89E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_89F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_89G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_89H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_90A",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_90B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_90C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_90D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_90E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_90F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_90G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_90H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4C_91A",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_91B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_91C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_92",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_93",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_94A",
    area: 54,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_94B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_95A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_95B",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_95C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_95D",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_95E",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_96A",
    area: 54,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_96B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_96B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_97A",
    area: 54,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_97B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_98A",
    area: 54,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_98B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_99",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_100",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_101A",
    area: 54,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_101B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_102A",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_102B",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4C_103",
    area: 72,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_104A",
    area: 36,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_104B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_104C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_105A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_105B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_105C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_105D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_106A",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_106B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_106C",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_107A",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_107B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_107C",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_108A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_108B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_108C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_108D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_109A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_109B",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_109C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_109D",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_110A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_110B",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_110C",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_111A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_111B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_111C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_111D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_112A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_112B",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_112C",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_112D",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_112E",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_113A",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_113B",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_114A",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_114B",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_114E",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_114C",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_114D",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_114E",
    area: 27,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_114F",
    area: 9,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_115A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_115B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_115C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_115D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_116A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_116B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_116C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_116D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_117A",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_117B",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_117C",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_117D",
    area: 18,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4D_118A",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_118B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_118C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_118D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_118E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_118F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_118G",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_118H",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_119A",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_119B",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_119C",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4E_119D",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_119E",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_119F",
    area: 9,
    hallId: 4,
    section: "sectionData_2",
  },
  {
    stallId: "Stall4D_120A",
    area: 27,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_120B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_120C",
    area: 27,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_121A",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_121B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_121C",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_121D",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_122A",
    area: 27,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_122B",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_122C",
    area: 27,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_123A",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_123B",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_123C",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_123D",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_123E",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_123F",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_124A",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_124B",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_124C",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_124D",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_124E",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_124F",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_124G",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_124H",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_125H",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4D_125G",
    area: 18,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_125E",
    area: 9,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_133",
    area: 12,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_134",
    area: 12,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_135",
    area: 12,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_136",
    area: 12,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_137",
    area: 12,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_138",
    area: 16,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_139",
    area: 16,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_140",
    area: 16,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_141",
    area: 16,
    hallId: 4,
    section: "sectionData_4",
  },
  {
    stallId: "Stall4E_142",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_143",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_144",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_145",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_146",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_147",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_148",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_149",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_150",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_151",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_152",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_153",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_154",
    area: 12,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_155",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_156",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_157",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_158",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_159",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_160",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_161",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_162",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
  {
    stallId: "Stall4E_163",
    area: 16,
    hallId: 4,
    section: "sectionData_1",
  },
];

const Hall5_layoutData = [
  {
    stallId: "Stall5F_164",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_165",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_166",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_167",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_168",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_169",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_170",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_171",
    area: 12,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_172",
    area: 12,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_173",
    area: 12,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_175",
    area: 9,
    hallId: 5,
    section: "sectionData_5",
  },
  {
    stallId: "Stall5F_176",
    area: 9,
    hallId: 5,
    section: "sectionData_5",
  },
  {
    stallId: "Stall5F_177",
    area: 9,
    hallId: 5,
    section: "sectionData_5",
  },
  {
    stallId: "Stall5F_178",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_179",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_180",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_181",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_182",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_183",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_184",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_185",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_186",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_187",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_188",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_189",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_190",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_191",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_192",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_193",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_194F",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_196",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_197",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_198B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_198A",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_199",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_200",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_201",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_202",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210E",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5F_210F",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5F_211A",
    area: 9,
    hallId: 5,
    section: "sectionData_9",
  },
  {
    stallId: "Stall5F_211B",
    area: 9,
    hallId: 5,
    section: "sectionData_9",
  },
  {
    stallId: "Stall5G_211C",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_211D",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_211E",
    area: 18,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5F_211G",
    area: 9,
    hallId: 5,
    section: "sectionData_9",
  },
  {
    stallId: "Stall5F_211H",
    area: 9,
    hallId: 5,
    section: "sectionData_9",
  },
  {
    stallId: "Stall5F_212A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_212B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_212D",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_212E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_212F",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_212G",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_212H",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_213A",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_213B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_213C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_214A",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_214B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_214C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_214D",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_215A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_215B",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_215C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_215D",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_215E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_215F",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_216A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_216B",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_216C",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_216D",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_216E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_216F",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_216G",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_216H",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217B",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217D",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_217C",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_217D",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_217E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_217F",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217G",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_217H",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_218A",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_218B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_218C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_218D",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_219A",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_219B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_219C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_220A",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_220B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_220C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_220D",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_221A",
    area: 54,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_221B",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_221C",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_221D",
    area: 18,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5F_222A",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_222B",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_222C",
    area: 9,
    hallId: 5,
    section: "sectionData_6",
  },
  {
    stallId: "Stall5G_222D",
    area: 9,
    hallId: 5,
    section: "sectionData_6",
  },
  {
    stallId: "Stall5G_222E",
    area: 18,
    hallId: 5,
    section: "sectionData_6",
  },
  {
    stallId: "Stall5F_222F",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_223A",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_223B",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_223C",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_223D",
    area: 18,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_224A",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5F_224B",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_224C",
    area: 9,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_224D",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_224E",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_224F",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_224G",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_224H",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_225A",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_225B",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_225H",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_225C",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_225D",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_225E",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_225F",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_225G",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_226A",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_226B",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_226C",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_226D",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_226E",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_226F",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_226G",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_226H",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_227A",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_227B",
    area: 9,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_227C",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_227D",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_227E",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5F_227F",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_229A",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_229B",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5H_229C",
    area: 36,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_229D",
    area: 18,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_230A",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_230B",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5H_230C",
    area: 36,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_230D",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_230E",
    area: 9,
    hallId: 5,
    section: "sectionData_3",
  },
  {
    stallId: "Stall5G_231A",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5H_231B",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5H_231D",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5H_231C",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_231C",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_231D",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_232A",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_232B",
    area: 18,
    hallId: 5,
    section: "sectionData_13",
  },
  {
    stallId: "Stall5G_233",
    area: 36,
    hallId: 5,
    section: "sectionData_7",
  },
  {
    stallId: "Stall5G_234",
    area: 72,
    hallId: 5,
    section: "sectionData_6",
  },
  {
    stallId: "Stall5G_235A",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_235B",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_236A",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_236B",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_237A",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_237B",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_238A",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_238B",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_239",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_240",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_241A",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_241B",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_242A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_242B",
    area: 54,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_242C",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_243A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_243B",
    area: 54,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_243C",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_244A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_244B",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_244C",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_244D",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_244E",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_244F",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_244G",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_244H",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_245A",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_245B",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5H_245C",
    area: 36,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_245G",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_245H",
    area: 9,
    hallId: 5,
    section: "sectionData_1",
  },
  {
    stallId: "Stall5G_246",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5G_247A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_247B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_247C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_247D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_248C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_248D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_248E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_248F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_249H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_249G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_249F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_249E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_254H",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_254G",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_254E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_255A",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_255B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_255C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_255D",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_255E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_255F",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_255G",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_255H",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_256A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_256B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_257A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_257B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_258A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_258B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_258C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_258D",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_258E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_258F",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_259A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_259B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_259C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_259D",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_259E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_259F",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_260A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_260B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_260C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_260D",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_260E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_260F",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_261A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_261B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_261C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_261D",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_261E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_262A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_262B",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_263",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_264",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_265A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_265B",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_266A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_266B",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_267A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_267B",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_268A",
    area: 54,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_268B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_269",
    area: 72,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_270",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_271",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_272A",
    area: 54,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_272B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },

  {
    stallId: "Stall5J_272B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_273A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_273B",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5H_274",
    area: 72,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_275A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_275B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_275C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_276A",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_276B",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_276C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_277A",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_277B",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_277C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_278A",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_278B",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_278C",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_278D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_278E",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_279A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },

  {
    stallId: "Stall5K_279B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_279C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_279D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_280A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_280B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_280C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_280D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_281A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_281B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_281C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_281D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_282A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_282B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_282C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_282D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_283A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_283B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_283C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_283D",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_284A",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_284B",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_285A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_285B",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_285C",
    area: 27,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_286A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_286B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_286C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_287A",
    area: 36,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_287B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_287C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_288A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_288B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_288C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_288D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_288E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_288F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_289A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_289B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_289E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_289C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_289D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_289E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_289F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_290A",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_290B",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_290C",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_291A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_291B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_291C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_291D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_291E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_291F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_292A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_292B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_292C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_292D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_292E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_292F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_293A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_293B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_293C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_293D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_293E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_293F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_294A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_294B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_294C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_294D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_294E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_294F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_295A",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_295B",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_295C",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_295D",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_295E",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_295F",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_296H",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5J_296G",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_296F",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_296E",
    area: 9,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_303",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_304",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_305",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_306",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_307",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_308",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_309",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_310",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_311",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_312",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_313",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_314",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_315",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_316",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_317",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_318",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_319",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_320",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_321",
    area: 12,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_322",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_323",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_324",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_325",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_326",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_327",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_328",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_329",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_330",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_331",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_332",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_333",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5K_334",
    area: 16,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5F_199",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_200",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_201",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_202",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_203",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_204",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_205A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_205B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_205C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_205D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_206A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_206B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_206C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_206D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_206E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_206F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_206G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_206H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_207F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_207H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208A",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208B",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_208C",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_208D",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209A",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209B",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_209C",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_209D",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5F_210A",
    area: 27,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_210B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_210E",
    area: 18,
    hallId: 5,
    section: "sectionData_2",
  },
  {
    stallId: "Stall5G_247",
    area: 36,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248A",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_248B",
    area: 36,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_248C",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_249A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_249B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_249C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_249D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_249E",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_249F",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_250A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_250B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_250C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_250D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_250E",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_250F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_250G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_250F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_251A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5G_251B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_251C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_251D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_252A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_252B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_252C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_252D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_253A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_253B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_253C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_253D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_253E",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_253F",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_253G",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_253H",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_254A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5H_254B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_254C",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_254D",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_296A",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5J_296B",
    area: 9,
    hallId: 5,
    section: "sectionData_14",
  },
  {
    stallId: "Stall5K_296C",
    area: 18,
    hallId: 5,
    section: "sectionData_14",
  },
];

export const HallLayoutData = {
  // [HALL_KEYS_CONSTANT_DATA.JWCC]: Hall_Jio_layoutData,
  // [HALL_KEYS_CONSTANT_DATA.h1]: Hall1_layoutData,
  // [HALL_KEYS_CONSTANT_DATA.h2]: Hall2_layoutData,
  // [HALL_KEYS_CONSTANT_DATA.h3]: Hall3_layoutData,
  [HALL_KEYS_CONSTANT_DATA.h4]: Hall4_layoutData,
  [HALL_KEYS_CONSTANT_DATA.h5]: Hall5_layoutData,
  // [HALL_KEYS_CONSTANT_DATA.h6]:  Hall6_layoutData,
  // [HALL_KEYS_CONSTANT_DATA.h7]: Hall7_layoutData,
};
