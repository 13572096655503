import React, { useEffect, useMemo, useState } from 'react'
import style from "./index.module.css";
import { BiUpArrow } from 'react-icons/bi';
import { isMobile } from 'react-device-detect';

const OPTIONS = [
    { value: null, label: "All" },
    { value: '9', label: '9' },
    { value: '12', label: '12' },
    { value: '16', label: '16' },
    { value: '18', label: '18' },
    { value: '27', label: '27' },
    { value: '36', label: '36' },
    { value: '54', label: '54' },
    { value: '72', label: '72' },
    { value: '108', label: '108' },
]

export const MyDropDown = ({ className, options = OPTIONS, onChnage = () => { }, children, autoWidth }) => {
    const [isOpen, setisOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(options[0])

    const maxChr = useMemo(() => {
        let len = 0
        options.forEach(d => {
            if (d.label.length > len) {
                len = d.label.length
            }
        })
        return len
    }, [options])

    useEffect(() => {
        onChnage(selectedOption);
        setisOpen(false);
    }, [selectedOption])

    return (
        <>
            <div className={`${className} ${style.filterKit}`}
                style={autoWidth ? {
                    width: `${maxChr}ch`
                } : {}}>
                <div className={`${style.filterArea} ${style.dflex}`} onClick={(e) => {
                    e.preventDefault();
                    setisOpen(prev => !prev);
                }}>
                    {
                        children(selectedOption)
                    }
                    <BiUpArrow className={style.arrowIcon} style={isOpen ? {
                        transform: "rotate(0deg)"
                    } : {
                        transform: "rotate(180deg)"
                    }} />
                </div>
                <div className={`${style.optionContainer} ${isOpen ? style.open : ""}`}
                    style={autoWidth ? {
                        width: `${maxChr}ch`,
                        minWidth: "100%"
                    } : {}}
                >
                    {
                        options.map(_o => (

                            <div className={style.option} key={_o.label} onClick={(e) => {
                                e.preventDefault();
                                setSelectedOption(_o)
                            }}>
                                {
                                    _o.label
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}